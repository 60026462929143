import {
  CostSubCategory,
  ICashFlowEntry,
  IChallenge,
  //     ICategory,
  ICostSubCategory,
  IFund,
  IFundApplication,
  IGoal,
  IProject,
  IProjectCreation,
  IProjectMembership,
  IProjectTask,
  IProposal,
  //     ISDG,
  IResourceRequirement,
  IUser,
  ImplementationState,
  MembershipRole,
  ProjectProgress,
  ProjectState,
  ProposalState,
  ResourceCostCategory,
  ResourceSource,
  ResourceSourceType,
  ResourceUnit,
  TimeDimension
} from "@api/schema"
import { IFilterCriteria } from "@redux/helper/actions"
import { OrderTypes } from "@redux/reduxTypes"
import { availableForSelection, challengeTypeAllowedForSelection } from "@services/challengeHelper"
import { getIDs, idFromIModelOrIRI, iriFromIModelOrString } from "@services/util"
import { IValidationErrors } from "@services/validation"

import { BlockerContext, PermissionBlockers } from "./blocker"
import { DynamicTranslate } from "./i18n"


export interface IProjectFilterCriteria extends IFilterCriteria {
  /** one or more categories */
  categories?: number | number[] | string | string[]
  /** ID of one or more project(s) */
  id?: number | number[] | string | string[]
  /** implementationstate of one or more project(s) */
  implementationState?: ImplementationState | ImplementationState[]
  /** locked projects: only for processmanager */
  locked: boolean
  /** name of a project */
  name?: string
  /** search pattern */
  pattern?: string
  /** progress of the project(s) */
  progress?: ProjectProgress | ProjectProgress[]
  /** one or more SDGs */
  sdgs?: number | number[] | string | string[]
  /** Slug of one or more project(s) */
  slug?: string | string[]
  /** state of the project */
  state?: ProjectState

  "order[id]"?: OrderTypes
  "order[name]"?: OrderTypes
  "order[updatedAt]"?: OrderTypes
  "order[createdAt]"?: OrderTypes
  "order[memberCount]"?: OrderTypes
}

/**
 * projectHelper: Collection of helper-functions for a IProject to be used in all components, dealing with calculation project-data.
 *
 */

/**
 * Path to the placeholder-image for projects
 */
export const projectIllustrationPlaceholder = "/assets/img/placeholder/illustration-project-profile.svg"

/**
 * shortDescription of a deleted project
 *
 * @todo currently for "normal users" this is the only option to recognize, if a project is deleted. deletedAt is not set! (Stand: 12.03.2023)
 */
export const deletedProjectShortDescription = "deleted project"


/** roles that users belong to the active team of a project */
export const TeamRoles = [
  MembershipRole.Coordinator,
  MembershipRole.Planner,
  MembershipRole.Observer,
]

/** roles that users do not belong to the active team of a project */
export const NonTeamRoles = [
  MembershipRole.Applicant,
]

/**
 * id of the own contributions view card
 */
export const idOwnContributionsView = "OwnContributionsView"

/**
 * ResourceWorkMode ist used as possible options for planning the ResourceRequirements,
 * to differenciate between complexe work-modes on ResourceRequirements
 */
export enum ResourceWorkMode {
  ResourcePlanning = "resources",
  FinancePlanning = "finance",
  CostCategoryPlanning = "costcategory",
}

export const currentGoalVersion = 1
export const currentResourceRequirementVersion = 2
export const currentCashFlowVersion = 1
export const currentCostSubCategoryVersion = 1

/** special type for ResourceRequirements in the used form */
export type ResourceRequirementsFormValues = IResourceRequirement & { unitCost: number; quantity: number; costSubCategory: string /* CostSubCategory */ }


/* ************************************************************************** */
/* Pre-filled data for initial objects based on existing schmemas             */
/* using the prefix "Fresh" to have a standard in the code                    */
/* ************************************************************************** */

/**
 * FreshGoal delivers a pre-filled IGoal to be used as an standard fresh goal
 */
export const FreshGoal: IGoal = {
  goal: "",
  measurability: "",
  version: currentGoalVersion
}

/**
 * FreshIdea delivers a pre-filled IProject to be used as an standard fresh idea as IProject
 */
export const FreshIdea: IProject = {
  progress: ProjectProgress.Idea,
  shortDescription: ""
}

/**
 * FreshProject delivers a pre-filled IProjectCreation to be used as standard fresh ProjectCreation
 */
export const FreshProject: IProjectCreation = {
  motivation: "",
  progress: ProjectProgress.CreatingProfile,
  skills: ""
}

/**
 * FreshProjectMembership delivers a pre-filled IProjectMembership to be used as standard fresh ProjectMembership
 */
export const FreshProjectMembership: IProjectMembership = {
  motivation: "",
  skills: ""
}

/**
 * a FreshCashFlowEntry delivers a pre-filled ICashFlowEntry
 */
export const FreshCashflowEntry: ICashFlowEntry = {
  timePoint: 0,
  unitCost: null,
  quantity: null,
  description: "",
  version: currentCashFlowVersion
}

/**
 * a FreshResourceRequirement delivers a pre-filled IResourceRequirement
 * must be instantiated with a task -> getFreshResourceRequirement
 */
export const FreshResourceRequirement: IResourceRequirement = {
  cashFlow: [
    FreshCashflowEntry
  ],
  cashFlowTimeDimension: TimeDimension.UnSet,
  customUnit: "",
  unit: ResourceUnit.Flat,
  costCategory: ResourceCostCategory.UnSet,
  title: "",
  version: currentResourceRequirementVersion
}

/**
 * a FreshCostSubCategory delivers a pre-filled ICostSubCategory
 * must be instantiated with a fund -> getFreshCostSubCategory
 */
const FreshCostSubCategory: ICostSubCategory = {
  type: CostSubCategory.Other,
  version: currentCostSubCategoryVersion
}

/**
 * all possible ProjectProgress-States, that stand for "planning" (and not for "idea")
 */
export const ProjectPlanStates = [
  ProjectProgress.CreatingProfile,
  ProjectProgress.CreatingPlan,
  ProjectProgress.CreatingProposal,
  ProjectProgress.SubmittingProposal,
  ProjectProgress.ProposalSubmitted,
  // excluding: ProjectProgress.Idea !
]

// #region team roles

/**
 * roles that belong to the active team of a project with reading rights
 */
export const TEAM_ROLES = [
  MembershipRole.Coordinator,
  MembershipRole.Planner,
  MembershipRole.Observer,
]

/**
 * roles that belong to the inner team of a project with writing rights
 */
export const INNER_TEAM_ROLES = [
  MembershipRole.Coordinator,
  MembershipRole.Planner,
]

/**
 *
 * @param project a project
 * @param user a user
 * @returns the role a given user has in a given project
 */
export const membershipRole = (project: IProject, user: IUser): MembershipRole => {
  if (!project || !user) {
    return null
  }

  return user.projectMemberships.find(membership => iriFromIModelOrString(membership.project) === iriFromIModelOrString(project))?.role || null
}

/**
 * @param project a project
 * @param user a user
 * @returns true, if a user is part of the INNER TEAM
 */
export const isInnerTeamMember = (project: IProject, user: IUser): boolean => {
  return INNER_TEAM_ROLES.includes(membershipRole(project, user))
}


// #endregion team roles

/* ************************************************************************** */
/* helper-functions                                                           */
/* ************************************************************************** */

// does the validation of resourceRequirements according to Backend > src/Dto/ResourceRequirement.php
// no restriction on relatedSourceObject
// NOTE: most of those validations may not be performed, because the browser performs similar checks earlier so that function is not called
export const validateResourceRequirement = (values: IResourceRequirement): IValidationErrors => {
  const errors: IValidationErrors = {}

  const title = values.title.trim()
  if (title.length === 0) {
    errors.title = "validate.general.notBlank"
  } else if (title.length < 3) {
    errors.title = "validate.general.tooShort"
  } else if (title.length > 100) {
    errors.title = "validate.general.tooLong"
  }

  const description = values.description.trim()
  if (description.length > 280) {
    errors.description = "validate.general.tooLong"
  }

  if (values.cashFlowTimeDimension === null || !Object.values(TimeDimension).includes(values.cashFlowTimeDimension)) {
    errors.cashFlowTimeDimension = "validate.general.invalidChoice"
  }

  if (!values.costCategory || !Object.values(ResourceCostCategory).includes(values.costCategory)) {
    errors.costCategory = "validate.general.invalidChoice"
  }

  if (values.costSubCategories?.length > 0) {
    const costSubCategoryErrorMessages: { [key: number]: { [key: string]: string } } = {}
    values.costSubCategories.forEach((valueCostSubCategory, index) => {
      costSubCategoryErrorMessages[index] = {}
      if (valueCostSubCategory.relatedFund !== undefined && (valueCostSubCategory.relatedFund < 1) ||
        (valueCostSubCategory.relatedFund > 99999999)) {
        costSubCategoryErrorMessages[index].relatedFund = "validate.relatedObject.invalid"
      }
      const type = valueCostSubCategory.type.trim()
      if (!type || type.length === 0) {
        costSubCategoryErrorMessages[index].type = "validate.general.notBlank"
      }
      if (type && type.length > 80) {
        costSubCategoryErrorMessages[index].type = "validate.general.tooLong"
      }
    })
    // only provide errors, if at least one error occured
    if (Object.keys(costSubCategoryErrorMessages).length > 0) errors.costSubCategory = costSubCategoryErrorMessages
  }

  if (values.sourceType && !Object.values(ResourceSourceType).includes(values.sourceType)) {
    errors.sourceType = "validate.general.invalidChoice"
  }

  if (values.source) {
    if (!Object.values(ResourceSource).find(value => values.source.match(value))) {
      errors.source = "validate.general.invalidChoice"
    }
  }

  const customSource = values.customSource?.trim()
  if (customSource?.length > 30) {
    errors.customSource = "validate.general.tooLong"
  }

  const customUnit = values.customUnit?.trim()
  if (customUnit?.length > 30) {
    errors.customUnit = "validate.general.tooLong"
  }

  if (!values.cashFlow || values.cashFlow?.length === 0) {
    errors.cashFlow = "validate.general.notBlank"
  } else {
    const cashFlow = values.cashFlow[0]
    const cashFlowErrorMessages: { [key: string]: string } = {}
    if (cashFlow) {
      const descriptionCashFlow = cashFlow.description?.trim()
      if (descriptionCashFlow?.length > 200) {
        cashFlowErrorMessages.description = "validate.general.tooLong"
      }
      if (cashFlow.quantity < 0 || cashFlow.quantity > 9999999.99) {
        cashFlowErrorMessages.quantity = "validate.general.outOfRange"
      }
      if (cashFlow.timePoint < 0 || cashFlow.timePoint > 99999999) {
        cashFlowErrorMessages.timePoint = "validate.general.outOfRange"
      }
      if (cashFlow.unitCost < 0 || cashFlow.unitCost > 9999999.99) {
        cashFlowErrorMessages.unitCost = "validate.general.outOfRange"
      }
      // only provide errors, if at least one error occured
      if (Object.values(cashFlowErrorMessages).length > 0) {
        errors.cashFlow = { 0: cashFlowErrorMessages }
      }
    }
  }

  const ownContributionsExplanation = values.ownContributionsExplanation?.trim()
  if (ownContributionsExplanation?.length > 200) {
    errors.ownContributionsExplanation = "validate.general.tooLong"
  }

  return errors
}


/**
 * @todo challenge: besserer Funktionsname? Zu Allgemein.
 * Checks if there are resourceRequirements linking to a fund, that is no longer available for proposals
 *
 * @param resourceRequirements A list of ResourceRequirements to check
 * @param funds to check for consistency
 * @returns true, if the given ResourceRequirements are not linked to an possible unavailable fund.
 */
export const checkSourceConsistency = (resourceRequirements: IResourceRequirement[], funds: IChallenge[]): boolean => {
  // precheck all given funds for its availability for proposals

  // prepare a list of all fund-IDs, that are available for proposals
  const validFunds = getIDs(funds?.filter(f => availableForSelection(f)))
  // search for at least one ResourceRequirement, that should be funded, has a relatedSourceObject and that
  // relatedSourceObject is not a valid challenge
  const foundRRWithDeprecatedFund = resourceRequirements?.find(rr => (rr.sourceType === ResourceSourceType.Funding) && rr.relatedSourceObject &&
    (!validFunds.includes(rr.relatedSourceObject as string)))
  return foundRRWithDeprecatedFund === undefined
}

/**
 * Calculate the sum of all cashflow-entries in all given ResourceRequirements.
 */
export const sumRequirementsCosts = (requirements: IResourceRequirement[]): number => {
  let sum = 0
  requirements?.forEach(rr =>
    rr.cashFlow?.forEach(cf => sum = sum + cf.quantity * cf.unitCost)
  )
  // do a separate rounding, because when used 13 units * 87,7 unitcost in a CashFlow-Entry, the Javascript-Engine
  // adds unexpected additional 2.2737367544323206e-13 !!!
  return Math.round(sum * 100) / 100 // rounding to 2 digits after comma
}

/**
 * Calculates the sum of a cashflow of a ResourceRequirement
 *
 * @param rr : the relevant ResourceRequirement
 * @returns the sum of the cashflow
 */
export const sumCashflowCost = (rr: IResourceRequirement): number => {
  let sum = 0
  // go thru all single cashflow-elements
  rr?.cashFlow?.forEach(cashflow => sum = sum + cashflow.quantity * cashflow.unitCost)
  return Math.round(sum * 100) / 100
}


/**
 * Calculate the sum of all cashflow-entries in the given ResourceRequirement.
 */
export const sumCostsOfResourceRequirement = (requirement: IResourceRequirement): number => {
  let sum = 0
  requirement?.cashFlow?.forEach(cf => sum = sum + cf.quantity * cf.unitCost)
  return Math.round(sum * 100) / 100
}

/**
 * Calculate the sum of quantity of all cashflow-entries in the given ResourceRequirement.
 */
export const sumQuantityOfResourceRequirement = (requirement: IResourceRequirement): number => {
  let sum = 0
  requirement?.cashFlow?.forEach(cf => sum = sum + cf.quantity)
  return Math.round(sum * 100) / 100
}

/**
 * Calculate the average of unitCost of all cashflow-entries in the given ResourceRequirement.
 */
export const averageUnitCostOfResourceRequirement = (requirement: IResourceRequirement): number => {
  let average = 0
  if (sumQuantityOfResourceRequirement(requirement) > 0) {
    average = sumCostsOfResourceRequirement(requirement) / sumQuantityOfResourceRequirement(requirement)
  }
  else {
    average = requirement?.cashFlow?.length > 0 ? requirement?.cashFlow[0].unitCost : 0
  }
  return Math.round(average * 100) / 100
}

/**
 * Calculate the sum of all costs in the given ResourceRequirements, that are assigned to the given CostCategory.
 */
export const sumCostsPerCostCategory = (requirements: IResourceRequirement[], costCategory: ResourceCostCategory): number => {
  return sumRequirementsCosts(requirements?.filter(rr => rr.costCategory === costCategory))
}


/**
 * Calculate the sum of all cashflow-entries of the project
 */
export const projectCosts = (project: IProject): number => {
  return sumRequirementsCosts(project?.resourceRequirements)
}

/**
 * Delivers a copy of a fresh IResourceRequirement, initiated with a given task (or null).
 *
 * @param task The initial task, the ResourceRequirement should be assigned to
 * @returns A fresh IResourceRequirement
 */
export const getFreshResourceRequirement = (task: IProjectTask): IResourceRequirement => {
  // prepare a fresh ResourceRequirement assigned to the current task
  const newResourceRequirement = Object.assign({}, FreshResourceRequirement)
  newResourceRequirement.task = task?.id
  return newResourceRequirement
}

/**
 * Delivers a copy of a fresh ICostSubCategory, initiated with a given fund (or null).
 *
 * @param fund The initial challenge, the SubCostCategory should be related to
 * @param category The initial SubCostCategory
 * @returns A fresh ICostSubCategory
 */
export const getFreshCostSubCategory = (fund: IFund, category?: CostSubCategory | string): ICostSubCategory => {
  // prepare a fresh ResourceRequirement assigned to the current task
  const newRRsub = Object.assign({}, FreshCostSubCategory)
  newRRsub.relatedFund = fund?.id
  if (category) newRRsub.type = category // overwrite the fresh type with the given one
  return newRRsub
}


/**
 * Filters for those ResourceRequirements of the given project, which are chosen to be funded by the given fund.
 *
 * @param project the project, which ResourceRequirements should be delivered
 * @param fund the fund, which is chosen as source for funding
 * @returns An array of ResourceRequirements from the given project.
 */
export const getResourceRequirementsFundedByFund = (project: IProject, fund: IFund): IResourceRequirement[] => {
  return project?.resourceRequirements?.filter(rr => rr.sourceType === ResourceSourceType.Funding && rr.relatedSourceObject === fund?.id) || []
}

/**
 * Filters for those ResourceRequirements of the given project, which are should funded by any fund
 *
 * @param project the project, which ResourceRequirements should be delivered
 * @returns An array of ResourceRequirements from the given project.
 */
export const getResourceRequirementsFundedByFundings = (project: IProject): IResourceRequirement[] => {
  return project?.resourceRequirements?.filter(rr => rr.sourceType === ResourceSourceType.Funding) || []
}


/**
 * Filters for those ResourceRequirements of the given project, which are funded by OwnResources
 *
 * @param project the project, which ResourceRequirements should be delivered
 * @returns An array of ResourceRequirements from the given project.
 */
export const getResourceRequirementsFundedByOwnResources = (project: IProject): IResourceRequirement[] => {
  return project?.resourceRequirements?.filter(rr => rr.sourceType === ResourceSourceType.OwnResources) || []
}

/**
 * Filters for those ResourceRequirements of the given project, which are funded by Proceeds
 *
 * @param project the project, which ResourceRequirements should be delivered
 * @returns An array of ResourceRequirements from the given project.
 */
export const getResourceRequirementsFundedByProceeds = (project: IProject): IResourceRequirement[] => {
  return project?.resourceRequirements?.filter(rr => rr.sourceType === ResourceSourceType.Proceeds) || []
}

/**
 * Filters for those ResourceRequirements of the given project, which are funded by ThirdParty
 *
 * @param project the project, which ResourceRequirements should be delivered
 * @returns An array of ResourceRequirements from the given project.
 */
export const getResourceRequirementsFundedByThirdParty = (project: IProject): IResourceRequirement[] => {
  return project?.resourceRequirements?.filter(rr => rr.sourceType === ResourceSourceType.ThirdParty) || []
}

/**
 * Filters for those ResourceRequirements of the given project, which do not have a funding yet
 *
 * @param project the project, which ResourceRequirements should be checked
 * @returns An array of ResourceRequirements from the given project.
 */
export const getResourceRequirementsWithoutFunding = (project: IProject): IResourceRequirement[] => {
  return project?.resourceRequirements?.filter(rr => !Object.values(ResourceSourceType).includes(rr.sourceType)) || []
}

/**
 * Delivers an array of ResourceRequirements, that are funded by own contributions: own efforts and own materials
 *
 * @returns the list of contribution or an empty list, if no own contribution is set yet
 */
export const getResourceRequirementsSourcedByOwnContributions = (project: IProject): IResourceRequirement[] =>
  project?.resourceRequirements
    ? project.resourceRequirements.filter(res => res.sourceType === ResourceSourceType.OwnResources && (res.source === ResourceSource.OwnMaterialContribution || res.source === ResourceSource.OwnEffortContribution))
    : []


/**
 * Returns all proposals of the project, that are not submitted yet
 *
 * @param project The project.
 * @returns all preparing (=not submitted) proposals
 */
export const getPreparingProposals = (project: IProject): (IProposal | IFundApplication)[] => {
  return project?.proposals?.filter((p) => p.state === ProposalState.Preparing)
}


/**
 * Returns helper to give goals the current version
 */
export const versionizeGoals = (goals: IGoal[]): IGoal[] => {
  return goals?.map((g) => {
    g.version = currentGoalVersion
    return g
  })
}

/**
 * Returns the number of coordinators in the team of the given project
 */
export const coordinatorCount = (project: IProject): number => {
  return project?.memberships?.filter(m => m.role === MembershipRole.Coordinator)
    .length || 0
}

/**
 * Returns the number of planners in the team of the given project
 */
export const plannerCount = (project: IProject): number => {
  return project?.memberships?.filter(m => m.role === MembershipRole.Planner)
    .length || 0
}

/**
 * Returns the number of observers in the team of the given project
 */
export const observerCount = (project: IProject): number => {
  return project?.memberships?.filter(m => m.role === MembershipRole.Observer)
    .length || 0
}



/**
 * get the list of those projects, where the user is the only coordinator
 *
 */
export const getOnlyCoordinatorProjects = (user: IUser, projects: IProject[]): IProjectMembership[] => {

  // list of those projects, where the user is the only coordinator
  const onlyCoordinatorProjects: IProjectMembership[] = []

  // list of the projects where the user is coordinator
  const coordinatedProjects = user?.projectMemberships?.filter(m => m.role === MembershipRole.Coordinator)

  // go through all its coordinated projects to watch for those projects, where he is the only coordinator within a team
  coordinatedProjects?.forEach(m => {
    const project = projects?.filter((p: IProject) => p && p['@id'] && p['@id'] === iriFromIModelOrString(m.project)).shift()

    if (project && coordinatorCount(project) === 1) {
      const memberCount = project
        // @todo Observer auch ausschliessen?
        .memberships?.filter((pm: IProjectMembership) => pm.role !== MembershipRole.Applicant)
        .length - 1 // subtract himself

      if (memberCount > 0) {
        onlyCoordinatorProjects.push(project)
      }
    }
  })
  return onlyCoordinatorProjects
}

/*
* this function transforms an IProject (e.g. from a project-json load or when a project should be copied)
* into an import-ready IProject by cleaning up import-relevant data
*/
export const cleanupProjectToImport = (oldProject: IProject): IProject => {
  let newProject: IProject

  if (oldProject) {

    // to rework links on fund-relevant entities in ResourceRequirements
    const newResourceRequirements: IResourceRequirement[] = oldProject.resourceRequirements ?? []
    // to rework links to category- and SDG-objects
    // const newCategories: (ICategory | string)[] = []
    // const newSdgs: (ISDG | string)[] = []

    // assign a new ID to all ResourceRequirements and rework links to non existing objects
    newResourceRequirements.forEach((rr) => {
      // clear link to a possible relatedSourceObject
      if (rr.relatedSourceObject) {
        rr.relatedSourceObject = ""
        rr.source = ""
      }
      // clear link to a possible relatedFund
      if (rr.costSubCategories && rr.costCategory.length > 0) {
        rr.costSubCategories = rr.costSubCategories.filter(csc =>
          csc.relatedFund === null
        )
      }
      // for older versions: rebuild cashflow, source, title, unit, version!
      switch (rr.version) {
        case (null || undefined): {
          rr.title = rr.description ?? ""
          rr.description = ""
          rr.version = currentResourceRequirementVersion
          rr.unit = ResourceUnit.Flat
          if (rr.sourceType === ResourceSourceType.Funding) rr.source = ""
          rr.cashFlow = [
            {
              quantity: 1,
              unitCost: 0, // (oldProject.resourceRequirements as [])[index]?.cost ?? 0, // @todo: is there an easy way to access "cost"-attribute of old ResourceRequirements, when the given parameter is a "new" IProject?
              timePoint: 0,
              version: currentCashFlowVersion
            }
          ]
        }
      }
    })

    // categories and SDGs must be converted into "plain" arrays while creating the new project
    // to be compatible with the create-process in the backend
    // @see: https://bitbucket.org/futurecityprojects/fcp-backend/src/4bdf67d0aeb72de7b9f828ce8597fab2bab518c5/tests/Api/ProjectApiTest.php#lines-2061
    // @todo multimandant: category- & SDG-IRIs beim import setzen, siehe: https://futureprojects.atlassian.net/jira/software/projects/FCP/boards/1?selectedIssue=FCP-518
    /*
    oldProject.categories?.forEach((c) => {
        if (typeof c === "string") {
            newCategories.push(c)
        } else {
            newCategories.push((c as ICategory)["@id"])
        }
    })

    oldProject.sdgs?.forEach((s) => {
        if (typeof s === "string") {
            newSdgs.push(s)
        } else {
            newSdgs.push((s as ISDG)["@id"])
        }
    })
    */


    newProject = {
      progress: /* oldProject.progress ?? */ ProjectProgress.CreatingProfile, // currently, the backend needs a fresh start of the ProjectProgress

      shortDescription: oldProject.shortDescription,
      description: oldProject.description,
      challenges: oldProject.challenges,
      partners: oldProject.partners,
      holderAddressInfo: oldProject.holderAddressInfo,
      holderCity: oldProject.holderCity,
      holderName: oldProject.holderName,
      holderStreet: oldProject.holderStreet,
      holderZipCode: oldProject.holderZipCode,
      bankName: oldProject.bankName,
      bic: oldProject.bic,
      // categories: newCategories as string[], // @todo: how to import?
      contactEmail: oldProject.contactEmail,
      contactName: oldProject.contactName,
      contactPhone: oldProject.contactPhone,
      delimitation: oldProject.delimitation,
      descriptionExtension: oldProject.descriptionExtension,
      expectedInvolvedPersons: oldProject.expectedInvolvedPersons,
      geoJson: oldProject.geoJson,
      goalExplanation: oldProject.goalExplanation,
      goals: oldProject.goals,
      iban: oldProject.iban,
      // not: implementationstate
      // not: inspiration -> must not be reused by imported projects!
      impact: oldProject.impact,
      implementationBegin: oldProject.implementationBegin,
      implementationTime: oldProject.implementationTime,
      implementationLocations: oldProject.implementationLocations,
      // not: membercount
      // not: memberships
      outcome: oldProject.outcome,
      // not: planSelfAssessment
      // not: pdfFile
      // not: picture
      // not: process -> // process-ID will be injected by saga before sending the new project-data to the backend-api
      // not: profileSelfAssessment
      results: oldProject.results,
      // not: resultingProjects
      resourceRequirements: newResourceRequirements,
      // sdgs: newSdgs as string[], // @todo: how to import?
      sdgExplanation: oldProject.sdgExplanation,
      // not: slug
      // not: state
      targetGroups: oldProject.targetGroups,
      tasks: oldProject.tasks,
      // not: teamContact
      // not: teamUploads
      // not: updatedAt
      // not: usedMemberRole
      // not: usedRoles
      utilization: oldProject.utilization,
      vision: oldProject.vision,
      // not: visualization
      workPackages: oldProject.workPackages,

    }
  }
  return newProject

}

/** ****************************************************************************************
 * The following section is about helper methods for handling the project workflow
 *
 * Follow links should be provided on certain pages, if the user has the rights and the conditions for the links are fullfilled:
 * 'which page' => left link, center link, right link:
 *
 * Projektplan => projekt-profil, project-dashboard, challenge-selection|proposal-dashboard
 * project-profile => project-dashboard, project-plan
 *******************************************************************************************/

/**
 * The project plan page should be accessible, if the project has created a project profile.
 *
 * @param project
 * @returns true,if a proposal overview should be available
 */
export const pageProjectPlanIsAccessible = (project: IProject): boolean =>
  project?.progress !== ProjectProgress.CreatingProfile

/**
 * A proposal overview should be available, if a project has at least one proposal and one proposal is active
 *
 * @param project
 * @returns true,if a proposal overview should be available
 */
export const pageProposalOverviewIsVisibleAndAccessible = (project: IProject): boolean =>
  project && project.proposals?.length >= 1
  && project.proposals?.filter(p => p.active).length === 1
  && project.progress !== ProjectProgress.CreatingProfile

/**
 * A challenge selection page should be accessible,
 * if the project has not progress state idea or creatingProfile.
 *
 * @param project
 * @returns true, if a challenge selection should be available
 */
export const pageChallengeSelectionIsVisibleAndAccessible = (project: IProject): boolean => {
  return ![ProjectProgress.Idea, ProjectProgress.CreatingProfile].includes(project.progress)
}

/**
 * A challenge is selectable,
 * if the proposal is not yet submitted or finished
 * and the challenge is still available for selection (published, open und deadline not reched yet)
 *
 * @param proposal
 * @param activeChallenge
 * @returns true, if a challenge selection should be available
 */
export const shouldChallengeBeSelectable = (proposal: IProposal | IFundApplication, activeChallenge: IChallenge, project: IProject): boolean => {
  return proposal && proposal.state === ProposalState.Preparing
    && idFromIModelOrIRI(proposal.challenge) === activeChallenge?.id
    && availableForSelection(activeChallenge)
    && challengeTypeAllowedForSelection(activeChallenge["@type"])
    && project.progress !== ProjectProgress.CreatingProfile
}

/**
 * Generate the project name text from a given project entity, using the new i18n model.
 *
 * NOTE (@todo remove this note): there is another `projectNameText` in /src/components/project/ProjectNameText.tsx
 * that has basically the same logic, but works with the old/depracated i18n.Translate function instead
 * of our new (namespace-aware) DynamicTranslate function.
 * The other/old method will be removed once the transition to the new (dynamic) i18n model is completed.
 *
 * @todo rename this method to `projectNameText`, and fix method description in JSDoc above
 *
 * @param project
 * @param t
 * @returns
 */
export const projectNameTextWithDynamicNamespaces = (project: IProject, t: DynamicTranslate): string =>
  project && project.progress === ProjectProgress.Idea
    ? t("project", "idea")
    : (project?.name
      ? project.name
      : t("project", "unnamedProject", { id: project?.id })
    )
// #region blockers

/**
 * @param isCoordinator is a user coordinator?
 * @returns blockers that block acting as coordinator
 */
export const blockerToPerformCoordinatorAction = (isCoordinator: boolean): BlockerContext => {
  const blockers: BlockerContext = {
    blocker: []
  }

  if (!isCoordinator) {
    blockers.blocker.push(PermissionBlockers.CoordinatorRequired)
  }

  return blockers
}
// #endregion
