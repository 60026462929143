import { NextRouter } from "next/router"

import { stringToInt } from "@services/util"

/**
 * To avoid typos and to allow changing the URLs later all links should use this enum
 * to organize existing pages.
 */
export enum Routes {
  // #region basic public pages
  Home = "/",
  About = "/about",
  Contact = "/contact",
  DataProtection = "/data-protection",
  Netiquette = "/netiquette",
  TermsOfUse = "/terms-of-use",
  Imprint = "/imprint",
  Pubtools = "/tools/pubtools",
  ProcessOverview = "/process",
  Categories = "/process/categories",
  ChallengePage = "/challenges/[slug]",
  // #endregion

  // #region Tools
  FAQ = "/tools/faq",
  // #endregion

  // #region user handling
  ConfirmAccount = "/user/confirm-account/[id]/[token]",
  ConfirmEmailChange = "/user/confirm-email/[id]/[token]",
  ConfirmPasswordReset = "/user/reset-password/[id]/[token]",
  Feedback = "/user/feedback",
  ForgotPassword = "/user/forgot-password",
  ImportProject = "/user/import-project",
  Login = "/user/login",
  Registration = "/user/register",
  UserProfile = "/user/profile",
  // #endregion

  // #region public project data/pages
  Marketplace = "/projects",
  ProjectPage = "/projects/[slug]", // also works for ID
  CreateIdea = "/ideas/create",
  CreateProject = "/projects/create?inspiration=[id]",
  ProjectApplication = "/projects/[slug]/apply",
  // #endregion

  // #region project pages for project team members
  MyProjects = "/user/projects",
  ProjectAttachments = "/projects/[slug]/challenge-proposal/attachments",
  ProjectProfile = "/projects/[slug]/profile",
  ProjectProfileEdit = "/projects/[slug]/profile/edit",
  ProjectSelectChallenge = "/projects/[slug]/challenge-proposal/select-challenge",
  ProjectConcretization = "/projects/[slug]/challenge-proposal/concretizations",
  ProjectConcretizationEdit = "/projects/[slug]/challenge-proposal/concretizations/edit",
  ProjectFeedbackDashboard = "/projects/[slug]/feedback",
  ProjectFeedbackPage = "/projects/[slug]/feedback/feedback",
  ProjectMembers = "/projects/[slug]/members",
  ProjectPartners = "/projects/[slug]/plan/partners",
  ProjectOwnContributions = "/projects/[slug]/plan/own-contributions",
  ProjectPlan = "/projects/[slug]/plan",
  ProjectPlanDescription = "/projects/[slug]/plan/description",
  ProjectPlanTargetgroupsImpact = "/projects/[slug]/plan/targetgroups-impact",
  ProjectPlanTasks = "/projects/[slug]/plan/tasks",
  ProjectPlanWorkPackages = "/projects/[slug]/plan/work-packages",
  ProjectPlanTimetable = "/projects/[slug]/plan/timetable",
  ProjectPlanResourceRequirements = "/projects/[slug]/plan/resource-requirements",
  ProjectPlanResourceCostCategories = "/projects/[slug]/plan/cost-categories?proposal=[proposal]",
  ProjectPlanFinances = "/projects/[slug]/plan/finances",
  ProjectProposals = "/projects/[slug]/challenge-proposal",
  ProjectStandingData = "/projects/[slug]/challenge-proposal/standing-data",
  ProjectStandingDataEdit = "/projects/[slug]/challenge-proposal/standing-data/edit",
  ProjectChallengeProposalEdit = "/projects/[slug]/challenge-proposal/edit",
  ProjectProposalChallengeModuleInActive = "/projects/[slug]/challenge-proposal/module-inactive-proposal-details",
  ProjectProposalInActive = "/projects/[slug]/challenge-proposal/inactive-proposal-details?proposal=[proposal]",
  ProjectProposalPostSubmission = "/projects/[slug]/challenge-proposal/post-submission",
  ProjectProposalSubmission = "/projects/[slug]/challenge-proposal/submission",
  TeamMeeting = "/projects/[slug]/teammeeting",
  TeamUpload = "/projects/[slug]/team-uploads",
  // #endregion

  // #region basic administrative pages for process manager / admin
  AdminDashboard = "/management",
  ProcessCreate = "/process/create",
  ProcessEdit = "/process/edit",
  ProcessCategories = "/management/process/[id]/categories",
  ProcessCategoriesAdd = "/management/process/[id]/categories/add",
  ProcessCategoriesEdit = "/management/process/[id]/categories/[category]/edit",

  AdminProjectSearch = "/management/projects",
  AdminProjectDetails = "/management/projects/[id]",
  AdminProjectProfile = "/management/projects/[id]/profile",

  AdminUserSearch = "/management/users",
  AdminUserDetails = "/management/users/[id]",
  AdminUserEdit = "/management/users/[id]/edit",

  AdminSystemOverview = "/management/system",
  AdminSystemIcons = "/management/system/icons",
  // #endregion

  // #region challenge/fund workflow
  // to differentiate the overview over challenges/funds we use a type-parameter
  AdminChallengeOverview = "/management/challenges?type=[type]",
  // the creation of a challenge may result in a BasicChallenge or a Fund. To diffenciate we use a type-parameter
  AdminChallengeCreate = "/management/challenges/create?type=[type]",
  AdminChallengeEdit = "/management/challenges/[id]/details/edit",

  AdminChallengeDetails = "/management/challenges/[id]/details",
  AdminChallengeDetailsEdit = "/management/challenges/[id]/details/edit",
  AdminChallengeConcretization = "/management/challenges/[id]/concretization",
  AdminChallengeConcretizationCreate = "/management/challenges/[id]/concretization/add",
  AdminChallengeConcretizationEdit = "/management/challenges/[id]/concretization/edit",
  AdminChallengeProcedureAndTimeline = "/management/challenges/[id]/procedure-timeline",
  AdminChallengeProcedureAndTimelineEdit = "/management/challenges/[id]/procedure-timeline/edit",
  AdminChallengeViewProposals = "/management/challenges/[id]/view-proposals",
  AdminChallengeSelectProposals = "/management/challenges/[id]/select-proposals",
  AdminChallengeGrant = "/management/challenges/[id]/grant",
  AdminChallengeGrantEdit = "/management/challenges/[id]/grant/edit",

  // transitioning the challege from one state to another are handled by one transition-page
  ChallengeTransitionPage = "/management/challenges/[id]/transition?transition=[transition]",
  // #endregion challenges/fund workflow

  // #region feedback for management
  AdminFeedbackInvitationTimeline = "/management/feedback/invitation/[id]/timeline",
  AdminFeedbackInvitationActivate = "/management/feedback/invitation/[id]/activate",
  AdminFeedbackInvitationCreate = "/management/feedback/invitation/create",
  AdminFeedbackInvitationEdit = "/management/feedback/invitation/[id]/edit",
  AdminFeedbackInvitationResults = "/management/feedback/invitation/[id]/results",
  AdminFeedbackInvitations = "/management/feedback/invitation",
  AdminFeedbackInvitationView = "/management/feedback/invitation/[id]",
  // #endregion

  // download-page for files/pdf
  DownloadTriggerPage = "/download/[type]/[id]",

  // #region external routes, e.g. direct API hits
  ProposalAttachmentDownload = "/proposal_attachments/[id]/download",
  ProposalPdfDownload = "/proposals/[id]/proposal-pdf-download",
  ProposalTeamPdfDownload = "/proposals/[id]/team-pdf-download",
  ProjectPdfDownload = "/projects/[id]/pdf-download",
  TeamUploadDownload = "/team_uploads/[id]/download",
  // #endregion
}

// #region helper functions for routes

/**
 * Creates a valid URL from a given route and given parameters, attached to this route
 *
 * @param route a valid route from pre-defined possible Routes
 * @param params a set of parameters
 * @param redirectBack a link, where the user should be sended AFTER finishing the job on the route-URL (must be implemented when needed on the route-url-page!)
 * @returns a valid URL set up from route and params
 */
export const routeWithParams = (
  route: Routes,
  params: { [param: string]: string | number },
  redirectBack?: string
): string => {
  let result: string = route
  // replace all params-keys, exististing in the pattern "[key]" in the route
  // by the value of the param-key, e.g.
  // params: {non: "sense"}
  // will replace all "[non]" elements in the route string by "sense"
  Object.keys(params).forEach((name) => {
    result = result.replace(`[${name}]`, params[name].toString())
  })

  if (redirectBack) {
    if (result.indexOf("?") === -1) {
      result = result + "?"
    } // attach "?" to attach parameters, if there is no "?" already
    result = result + "&redirectBack=" + encodeURIComponent(redirectBack) // attach parameter "redirectBack"
  }

  return result
}

/**
 * Returns a parameter from the current URL if they are string-able.
 *
 * @param router a given router
 * @param param the expected parameter of the url
 * @returns the value of the parameter, if it is a string, otherwise null
 */
export const getStringFromQuery = (router: NextRouter, param: string): string => {
  const value = router.query[param]
  return typeof value === "string" ? value : null
}

/**
 * Returns a parameter from the current URL as an integer.
 *
 * @param router a given router
 * @param param the expected parameter of the url
 * @returns the value of the parameter if it is convertable to an integer, otherwise null
 */
export const getIntFromQuery = (router: NextRouter, param: string): number =>
  stringToInt(getStringFromQuery(router, param))

/**
 * Removes all query parameters from the current URL.
 *
 * @param router a given router
 */
export const removeAllQueryParams = (router: NextRouter): void => {
  void router.replace(
    router.pathname,
    router.pathname,
    { shallow: true }
  )
}
// #endregion helper functions