import { EntityType } from "@redux/reduxTypes"
import { iriFromIModelOrString, isEmptyNullOrUndefinedObject } from "@services/util"

import { AbstractProjectUserRelation, INumericIdentifierModel, IProjectFollowership, IUser } from "./schema"

/**
 * This file contains data structures that are used to send data to the API ("write DTOs").
 *
 * Some API create/update calls expect specialized structures, that differ from "normal" entity definitions.
 * Those are defined in here, including a transformation method.
 *
 * Everything is still a sketch (as of 2023-10-06) and may be repaced by a more sophisticated OOP approach.
 * See also doc/principles/data-schema.md
 */


/**
 * IUserWriteDTO extends IUser and redefines some fields according to doc/principles/data-schema.md.
 * The IUser attributes are initialized from information the user enters in the "registration" form
 * as well as onboarding elements he created before registration (cf doc/usecases/onboarding.md).
 * We use a special API endpoint to handle that.
 */
export interface IUserWriteDTO extends Omit<IUser, "followerships"> {
  followership?: IProjectFollowershipWriteDTO
  validationUrl: string
}


/**
 * An IProjectFollowershipWriteDTO represents a followership connection between a user and a project.
 * NOTE since the API requires strings on CREATE actions, we apply doc/principles/data-schema.md "Receiving objects, sending IRIs"
 */
export interface IProjectFollowershipWriteDTO extends AbstractProjectUserRelation {
  project: string
}


/**
 * Prepare entities (or other schema objects) before calling the API's create method resp. before
 * POSTing entities/objects to the API.
 * If transformation is necessary, it returns a WriteDTO, as defined above, that mostly matches
 * the EntityType (or interface type) of the given object, but may differ in certain attributes.
 *
 * This may be necessary due to following reasons:
 *
 * 1) Transforming nested documents to simple IRIs:
 * The (API-side) EntityType allows INumericIdentifierModel | string for a certain property,
 * and we want to use the model object in the client before calling create for a richer user experience,
 * but the API does not accept nested documents in create calls, instead expects IRIs.
 *
 * @param entityType the entitytype of the given entity
 * @param model the entity (or other schema object) to be prepared
 * @returns a (possibly changed) entity (or other schema object) only useful for sending POST and PUT requests
 */
export const transformEntityToWriteDTO = <T extends INumericIdentifierModel>(entityType: EntityType, model: INumericIdentifierModel): T => {

  if (isEmptyNullOrUndefinedObject(model)) {
    return model as T
  }

  switch (entityType) {

    case EntityType.ProjectFollowership:
      // NOTE it may or not be that model already has been transformed to a WriteDTO, so we must handle both.
      // We do this by adding the elements to `projectFollowershipWriteDTO` in a very special order.
      const projectFollowership = model as IProjectFollowership | IProjectFollowershipWriteDTO
      // transform all nested objects to IRIs (or implicitely keep IRIs)
      const projectFollowershipWriteDTO: IProjectFollowershipWriteDTO = {
        ...projectFollowership,
        user: iriFromIModelOrString(projectFollowership.user),
        project: iriFromIModelOrString(projectFollowership.project)
      }

      return projectFollowershipWriteDTO as undefined as T

    case EntityType.User:
      // NOTE it may or not be that `model` already has been transformed to a WriteDTO, so we must handle both.
      // We do this by adding the elements to `userWriteDTO` in a very special order.
      const user = model as IUser | IUserWriteDTO
      const userWriteDTO: IUserWriteDTO = {
        // NOTE since user may be a IUserWriteDTO and already contain a validationUrl, we must allow overwriting in the next+1 line
        validationUrl: undefined,
        followership: transformEntityToWriteDTO(EntityType.ProjectFollowership, (user as IUser).followerships?.shift()),
        ...user,
      }

      return userWriteDTO as undefined as T
  }

  return model as T
}