/**
 * This file contains all Entities and DTOs as "schema" used by the client and shared with the backend/API.
 * It also contains used enumerations (enums), and standard "fresh"-objects based on the schemas,
 * as well as examples for testing.
 */

import { AvailableContentItems } from "@components/common/contentmanagement/ContentManagementHelper"


/* ************************************************************************** */
/* Interfaces: used schema for all entities and DTOs                          */
/* using the prefix I to have a standard in the code                          */
/* ************************************************************************** */

// #region basic technical schemas

/* ********** Technical schemas ********************************************* */

/**
 * An IActionResult represents the state of an Redux-Action. It can be successful or not and includes
 * an explaining message.
 */
export interface IActionResult {
  success: boolean
  message: string
}


/**
 * An IHydraCollection is used for the transport of Entities based on IModel to the backend-API.
 */
export interface IHydraCollection<T extends IModel> {
  "@context"?: string
  "@id"?: string
  "@type"?: string
  "hydra:firstPage"?: number
  "hydra:itemsPerPage"?: number
  "hydra:lastPage"?: number
  "hydra:member"?: T[]
  "hydra:nextPage"?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  "hydra:search"?: object // @todo
  "hydra:totalItems"?: number
  "hydra:view"?: {
    "hydra:first"?: string
    "hydra:last"?: string
    "hydra:next"?: string
  }
}

/**
 * An IModel is the basic model of all used Entities.
 */
export interface IModel {
  "@id"?: string
  /** usedRoles are those roles the users request was handled by the backend (user may have more than one role, but the request was performed as one of them, e.g. ProcessManager) */
  usedRoles?: UserRole[]
  detailResult?: boolean // true, if all data of the Object was retrieved; false, if the object was loaded as part of a collection only
}

/**
 * An INumericIdentifierModel extends the IBasisIModel by a numeric identifier,
 * so all unique entities can by identified by that id.
 */
export interface INumericIdentifierModel extends IModel {
  id?: number
}

/**
 * An ISlugIdentifierModel extends the INumericIdentifierModel by a string identifier,
 * so all unique entities can by identified by that slug.
 */
export interface ISlugAndNumericIdentifierModel extends INumericIdentifierModel {
  slug?: string
}

/**
 * Statistics are Records of strings for keys and numbers as values
 */
export type IStatistics = Record<string, number>

// #endregion

/* ********** Schemas for user and login handling ********************************************* */

/**
 * An IAuthReply represents a token for an authentificated connection between client and backend and can be used
 * to order a new token.
 */
export interface IAuthReply {
  token: string // encoded JWT
  refresh_token: string // token string to request a new JWT
  refresh_token_expires: number // unix timestamp when the refresh-token expires
}



export interface IEmailChange {
  confirmationPassword: string
  email: string
  validationUrl?: string
}

export interface INewPasswordRequest {
  validationUrl?: string
}

export interface IPasswordChange {
  confirmationPassword: string
  password?: string
}

export interface IPasswordReset extends IValidation {
  password: string
}

export interface IPasswordResetRequest {
  username: string
  validationUrl?: string
}

export interface IValidation {
  id: string
  token: string
}

// is used to send an email to certain users and the sender will get a confirmation email
// @see src/redux/saga/users.ts: sendMenssageToAllUsersSaga
export interface IUserEmail extends ITeamEmail {
  receiverOption: UserEmailReceiverOption
}

/* ********** Content schemas ********************************************* */



/**
 * An IAddress represents an address of an institution or person.
 */
export interface IAddress {
  addressInfo?: string
  city?: string
  contactPerson?: string
  country?: string  // ISO 3166-1 alpha-2
  email?: string
  geoJson?: string[]
  name: string
  phone?: string
  postalCode?: string
  street?: string
  url?: string
  version: number
}

/**
 * A IProposalContent represents a single key of content (contentId), that is or is not provided to a proposal.
 * The inner ObligationState defines, if this content must or could be included or if it is irrelevant.
 */
export interface IProposalContent {
  contentId: AvailableContentItems
  requirement: ObligationState
  version: number
}


/**
 * An IProposalAttachment represents an attachment to an proposal to a challenge. It is possibly connected to
 * an AttachmentDefinition of the challenge, where the proposal is created for.
 */
export interface IProposalAttachment extends INumericIdentifierModel {
  proposal?: IProposal | IFundApplication | string
  definition?: IAttachmentDefinition | string
  file?: File | IMediaObject
}

export interface IContactEmail {
  message: string
  senderName: string
  senderEmail: string
  topic: ContactTopic
}

/**
 * An ICategory is used to organize the categories of a project.
 * If a Category does not have properties, their display name should be created using their ID and translated via I18N.
 */
export interface ICategory extends INumericIdentifierModel {
  /** short title of the category, e.g. "Kunst & Kultur"  */
  title: string
  /** a longer describing subtitle */
  subtitle?: string
  /** even longer description of the category */
  description?: string
  /** list of problems, related to a category */
  problems?: string[]
}


/**
 * An ICashFlowEntry represents a single flow of cash.
 * It consists of a number of costs (count) at a certain point in time, with an optional note.
 */
export interface ICashFlowEntry {
  timePoint: number
  unitCost: number
  quantity: number
  description?: string
  version: number
}

/**
 * An ICostSubCategory represents the name of a subtype of costs, depending on a fund.
 * It allows to adopt the naming of a subtype to the specific needs of a fund.
 * */
export interface ICostSubCategory {
  type: CostSubCategory | string // maybe an element from the given enum or an custom CostSubCategory from the given fund
  relatedFund?: number // id of the fund
  version: number
}

/**
 * An ICredentials represents the login-data for a single user.
 */
export interface ICredentials {
  username: string
  password: string
}

/**
 * An IDiscussion represents an discussion, consisting of IFeedbackPost, possibly regarding to an IFeedbackInvitation
 * regarding a relatedObject
 */
export interface IDiscussion extends INumericIdentifierModel {
  /** when commenting a project (as relatedObject) a special aspect of that project may be commented. Which one is encoded in the contendId */
  contentId: string | AvailableContentItems
  /** date-time, when the discussion was created */
  createdAt?: Date | string // type: date-time
  /** user qho created tha discussion, may be null */
  createdBy?: IUser
  /** if the discussion was an answer to an FeedbackInvitation */
  feedbackInvitation?: IFeedbackInvitation | string
  /** date-time of the youngest post */
  lastPostDate?: Date | string
  /** number of posts to a discussion */
  postCount?: number
  /** the initial post is that one, that founded the discussion */
  initialPost: IFeedbackPost
  /** a discussion may relate to an object of type IProject, IProcess or IUser. Either relatedObject OR feedbackInvitation may be set */
  relatedObject?: string
}



/**
 * An IFeedbackInvitation represents an invitation for feedback
 */
export interface IFeedbackInvitation extends INumericIdentifierModel {
  activeUntil?: Date | string // type: date-time | null
  anonymousFeedback?: boolean
  createdAt?: Date | string // type: date-time
  createdBy?: IUser
  description?: string
  discussionCount?: number
  questions: IFeedbackQuestion[]
  receiver?: string
  relatedObject?: string
  title: string
  type?: string
}

/**
 * An IFeedbackPost represents a single post, connected to an IDiscussion answering to an IFeedbackInvitation
 */
export interface IFeedbackPost extends INumericIdentifierModel {
  content: { [key: string]: string }  // single content-elements, 1 item for comments, more than 1 item for question-lists of FeedbackInvitations
  createdAt?: Date | string // type: date-time
  createdBy?: IUser // user or null (when anonymous)
  discussion?: IDiscussion | string
  parent?: string // optional pre-post, this FeedbackPost relates to; IRI-Reference
  type: FeedbackPostType // type of the post
}

/**
 * A IFeedbackQuestion represents a single question for an IFeedbackInvitation
 */
export interface IFeedbackQuestion {
  id: string
  explanation?: string
  question: string
  version: number
}

/**
 * An IChallenge represents a challenge, consisting of its name, description, timeline concretization-questions, needed
 * attachments for proposals and a list of received proposal.
 */
export interface IChallenge extends ISlugAndNumericIdentifierModel {
  "@type"?: ChallengeType
  attachmentDefinitions?: IAttachmentDefinition[] // list of definitions of attachments, that must or should be provided to the proposal
  attachmentsAvailable?: boolean // true, if attachments are accepted to a proposal
  concretizations?: IChallengeConcretization[] // additional questions that are provided to the project team
  criteria?: string[]
  description?: string
  directSubmission?: boolean
  implementationBegin?: Date | string
  imprint?: string
  logo?: IMediaObject
  name?: string
  process?: IProcess | string
  /** required or wanted contents for a proposal for that specific challenge */
  proposalContents?: IProposalContent[]
  region?: string
  selectionProcess?: ChallengeSelectionProcess
  sponsor?: string
  state?: ChallengeState
  submissionBegin?: Date | string
  submissionEnd?: Date | string
  transitions?: ITransitionList
}

/**
 * An IFund is special challenge, which adds the option to support a project with money.
 * It only accepts fund applications as a proposal.
 */
export interface IFund extends IChallenge {
  budget?: number // budget of the fund
  costTypesRequired?: boolean // is it required to attach costTypes to ResourceRequirements by applicators?
  customMaterialCostTypes?: string[] // which custom cost types for materialcosts are required by the fund
  fundingDuration?: number
  fundingRate?: number
  maxFundedHourlyRate?: number // what is the maximum rate per hour, a fund accepts for ownResources (kalkulatorischer Stundensatz für erbrachte Eigenleistungen)
  maximumGrant?: number
  minimumGrant?: number
  ownContributionsAccepted?: boolean // is it possible to use ownResources (Eigenleistungen) in an application, if the fund-quota is less than 100%?
  supportedMaterialCostTypes?: string[] // which CostSubCategory have to be assigned to ResourceRequirements of Type "material costs"
}

/**
 * An IFundApplication represents an application for a funding of a fund.
 * The Omit type is used to override the property state in IProposal
 */
export interface IFundApplication extends Omit<IProposal, 'state' | 'submissionData'> {
  requestedFunding?: number
  state?: FundApplicationState
  /** public readable submitted data*/
  submissionData?: IApplicationSubmissionData
  /**
   * @todo add thirdPartyFundsExplanation in a form for the user => currently no usage
   */
  thirdPartyFundsExplanation?: string // Explanation of the usage of Third-Party-Funds
}
/**
 * An IProposal represents an proposal for a challenge.
 */
export interface IProposal extends INumericIdentifierModel {
  "@type"?: ProposalType
  /** is this proposal activated for the current project? */
  active?: boolean
  /** is the proposal recommended for approvement? */
  approvalRecommendation?: ApprovalRecommendation
  /** is the proposal approved? ("bewilligt") */
  approved?: boolean
  /** list of attachments belonging to this proposal */
  attachments?: IProposalAttachment[]
  /** list of concretizations belonging to this proposal */
  concretizations?: { [id: number]: string }
  /** self assessment about the progress of the concretizations */
  concretizationSelfAssessment?: SelfAssessment
  /** challenge to which the proposal belongs: Entity or IRI */
  challenge?: IChallenge | string
  /** if the challenge has a voting: is this proposal licensed to take part in the voting? */
  eligibleForVoting?: boolean
  /** rank of the proposal within its recommondation */
  preselectionOrder?: number
  /** hint: if the proposal was directly accessed through the proposal endpoint by a processmanager, the proposal has the project property,
   * otherwise the project proerty is not available.
   * project the proposal belongs to: Entity or IRI */
  project?: IProject | string
  /** Proposal-PDF, adopted to the chosen ContentManagement of a given challenge */
  proposalPdfFile?: IMediaObject
  /** self assessment about the progress of the proposal */
  proposalSelfAssessment?: SelfAssessment
  /** public readable submitted data */
  publicSubmissionData?: IProposalSubmissionData
  /** rank of the proposal in an (optional) voting of the challenge */
  publicVotingResult?: number
  /** state of the proposal
   * is redefined for IFundApplication
   *
   * @todo: maybe rename entity attribute to submissionState to be more precise
   */
  state?: ProposalState
  /** all (even non-public) submitted data */
  submissionData?: IProposalSubmissionData
  /** all-inclusive PDF-file with all project-data as snapshot from the moment of the proposal-generation */
  teamPdfFile?: IMediaObject
  /** additional application text for taking part of a voting */
  votingApplication?: string
}

/**
 * An IAttachmentDefinition represents a definition of an attachment, that must/should/could attached
 * to a proposal for a challenge.
 */
export interface IAttachmentDefinition extends INumericIdentifierModel {
  content?: string
  description?: string
  challenge?: string | IChallenge
  maxSize?: number
  mimeTypes?: string[]
  required?: boolean
}

/**
 * An IChallengeConcretization represents a single question, that a challenge additionally asks an applicating project team.
 */
export interface IChallengeConcretization extends INumericIdentifierModel {
  description?: string
  challenge?: IChallenge | string
  image?: IMediaObject
  maxLength?: number
  question?: string
}

/**
 * IChallengeStatistics is used to transfer statistic information about the number of challenges with different states
 * in the running system from the backend to the client.
 */
export interface IChallengeStatistics extends IStatistics {
  /** number of existing challenges */
  funds: number
  basicChallenges: number
  /** number of currently published challenges */
  published: number
  /** number of currently open funds */
  open: number
  /** number of currently paused funds */
  paused: number
  /** number of currently challenges in state "deciding" */
  deciding: number
  /** number of currently challenges in state "fulfilling" */
  fulfilling: number
  /** number of currently challenges in state "clearing" */
  clearing: number
  /** number of currently finished funds */
  finished: number
}


/**
 * Each goal consists of the a description itself as well as a text for measurability of Achievement of the goal.
 */

export interface IGoal {
  goal: string
  measurability: string
  version: number
}

/**
 * An IMediaObject represents a media object, e.g. an image. It is used to handle all kinds of files.
 */
export interface IMediaObject {
  contentUrl: string
  mimeType: string
  name: string
  originalName: string
  size: number
  dimensions?: [number, number]
  createdAt?: Date | string
  createdBy?: IUser
}

/**
 * An IProcess represents a participation process, organized to generate project ideas and projects.
 * It could include challenges of those projects.
 */
export interface IProcess extends ISlugAndNumericIdentifierModel {
  criteria?: string[]
  description?: string
  challenges?: IChallenge[]
  goals?: string[]
  imprint?: string
  logo?: IMediaObject
  maxTeamUploadSize?: number
  maxTeamUploadTotalSize?: number
  name?: string
  projects?: IProject[]
  region?: string
}

/**
 * An IProject represents a project. Projects are central entities in the system. Most activities of the user aim
 * at improving project plans.
 */
export interface IProject extends ISlugAndNumericIdentifierModel {
  bankName?: string
  bic?: string
  categories?: ICategory[] // | string[] // must by ICategory, or ("flat") string when re-importing project-data
  challenges?: string
  contactEmail?: string
  contactName?: string
  contactPhone?: string
  createdAt?: Date | string
  createdBy?: IUser
  expectedInvolvedPersons?: number
  deletedAt?: Date | string
  delimitation?: string
  description?: string
  descriptionExtension?: string
  feedbackAllowedFor?: FeedbackRoles[]
  followerCount?: number
  followerships?: IProjectFollowership[]
  geoJson?: object | [] // @todo replace with GeoJson.Feature | FeatureCollection when the corresponding "map" branch is merged
  goals?: IGoal[]
  goalExplanation?: string
  holderAddressInfo?: string
  holderCity?: string
  holderName?: string
  holderStreet?: string
  holderZipCode?: string
  iban?: string
  impact?: string[]
  implementationBegin?: Date | string
  implementationLocations?: string[]
  implementationState?: ImplementationState
  implementationTime?: number
  inspiration?: IProject | string
  locked?: boolean
  memberCount?: number // includes coordinators, planners + observers
  memberships?: IProjectMembership[]
  name?: string
  outcome?: string[]
  partners?: IProjectPartner[]
  pdfFile?: IMediaObject
  picture?: IMediaObject
  planSelfAssessment?: SelfAssessment
  process?: IProcess | string
  profileSelfAssessment?: SelfAssessment
  progress?: ProjectProgress
  proposals?: (IProposal | IFundApplication)[]
  resourceRequirements?: IResourceRequirement[]
  resultingProjects?: IProject[]
  results?: string[]
  sdgs?: ISDG[] // | string[] // must be ISDG, or ("flat") string when re-importing project-data
  sdgExplanation?: string
  shortDescription?: string
  state?: string // @todo: my this be type of ProjectState ?
  targetGroups?: string[]
  tasks?: IProjectTask[]
  teamContact?: IUser
  teamUploads?: ITeamUpload[]
  updatedAt?: Date | string
  usedMemberRole?: MembershipRole
  utilization?: string
  vision?: string
  visualization?: IMediaObject
  workPackages?: IWorkPackage[]
}

/**
 * An IProjectCreation is an IProject, extended by additional fields to create a project.
 * Those additional fields are required when a user creates a project,
 * and are moved to his fresh project-membership backend-side.
 */
export interface IProjectCreation extends IProject {
  // user: the creating user is implicit via token of the request when the new project is sent to the api: he should be logged in
  motivation: string
  skills: string
}

/**
 * An abstract interface that represents all relationships between project and user.
 * It is only used on client side and allows easier handling of equivalent client-side data structures
 * (i.e. IProjectFollowership and IProjectMembership).
 */
export interface AbstractProjectUserRelation extends IModel {
  project?: IProject | string
  user?: IUser | string
}

/**
 * An IProjectFollowership represents a followership connection between a user and a project.
 * This specific interface models project followerships that contains a project object (instead of IRI),
 * as it is sent from the API in get-user-self usecase.
 */
export interface IProjectFollowership extends AbstractProjectUserRelation, INumericIdentifierModel {
  createdAt?: Date | string
  /**
   * We specialize to IProject and omit string, b/c it's guaranteed that all received data (from API) will contain the object.
   * NOTE since the API requires strings on CREATE actions, we apply doc/principles/data-schema.md "Receiving objects, sending IRIs".
   * In this case, use `transformEntityToWriteDTO` from schema-dto.ts to get a `IProjectFollowershipDTO`.
   */
  project: IProject
}


/**
 * An IProjectImport represents a temporary object for a JSON-Upload and an optional new name for the new project
 */
export interface IProjectImport {
  file?: File
  newName?: string
  motivation: string
  skills: string
}



/**
 * An IProjectMembership represents a membership connection between a user and a project.
 * It consists of the users role and his skills and motivation to this single project.
 */
export interface IProjectMembership extends AbstractProjectUserRelation {
  motivation?: string
  role?: MembershipRole
  skills?: string
}


/**
 * An IPartner represents an project partner and its contribution to the project.
 */
export interface IProjectPartner {
  address?: IAddress
  contribution?: string
  domain?: string
  id?: string
  partnershipState?: PartnershipState
  version: number
}

/**
 * An IProjectReport represents a misuse-report of a project.
 */
export interface IProjectReport {
  reporterEmail: string
  reporterName: string
  reportMessage: string
}

/**
 * IProjectStatistics is used to transfer statistic information about the number of projects with different states
 * in the running system from the backend to the client.
 */
export interface IProjectStatistics extends IStatistics {
  /** number of ideas, not projects */
  ideas: number
  /** number of ideas created within the last 24 hours */
  newIdeas: number
  /** number of projects, not ideas */
  projects: number
  /** number of projects created within the last 24 hours */
  newProjects: number
  /** number of deleted ideas and projects */
  deleted: number
  /** number of inactive ideas and projects */
  inactive: number
  /** number of deactivated ideas and projects */
  deactivated: number
}


/**
 * An IProjectTask represents an task or a job to be done, to implement a project. To plan a project its central
 * to collect all necessary tasks. A task is fulfilled over a flow of time and it can be part of a work package.
 */
export interface IProjectTask {
  description?: string
  id?: string
  // in which months of the projects implementationTime this task will be worked on
  // e.g. 1,2,3 for the first 3 months
  months?: number[]
  result?: string
  title?: string
  workPackage?: string // ID of the parent IWorkPackage
  order?: number
}


/**
 * IPublicStatistics is used to transfer public statistic information about the running system from the backend to the client.
 */
export interface IPublicStatistics extends IStatistics {
  /** number of active challenges */
  activeChallenges: number
  /** number of active project ideas, not projects */
  activeIdeas: number
  /** number of active projects, not ideas */
  activeProjects: number
  /** number of projects in progress state "finished" */
  finishedProjects: number
  /** number of projects in progress state "running" */
  runningProjects: number
  /** number of projects in progress state "planning" */
  planningProjects: number
  /** number of active processes */
  processes: number
  /** number of active users */
  users: number
}

/**
 * An IResourceRequirement represents a resource, that is needed to fulfill a task or a workpackage.
 * It consists of a list of CashFlow-Elements, those sum stands for the needes costs for that ResourceRequirement.
 *
 * A ResourceRequirement needs a source to be fulfilled.
 * A source has a sourceType: funding, ownResources, other.
 * A source can be a fund, own funds, contributions or material or thirdParty/other sources.
 * A source can point on a relatedSourceObject: a Fund-ID or a ProjectPartner-ID
 */
export interface IResourceRequirement {
  cashFlow?: ICashFlowEntry[]
  cashFlowTimeDimension?: TimeDimension // in which dimension is the cashflow measured?
  costCategory?: ResourceCostCategory // costCategory, like "Investment", "Personnel", "Material"
  costSubCategories?: ICostSubCategory[] // a list of fund-depending subType-Definitions
  customUnit?: string
  customSource?: string
  description?: string
  ownContributionsExplanation?: string
  id?: string
  relatedSourceObject?: number | string // valid fund-id or partner-id
  source?: ResourceSource | string // to precise the source of the resource
  sourceType?: ResourceSourceType // the type of the source
  task?: string // ID of the parent IProjectTask (alternate to workPackage | null)
  title: string
  unit: ResourceUnit // psysical unit of (e.g. number, hours, km, meals, ...)
  version: number
  weeklyHours?: number // for financialType === personnel only
  workPackage?: string // ID of the parent IWorkPackage (alternate to task | null)
}


/**
 * An ISDG is used to organize the SDGs, to which a project is connected.
 * SDGs have no properties, their display name is created using their ID and translated via I18N.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISDG extends INumericIdentifierModel { }


/**
 * An IProposalSubmissionData represents an proposal to a challenge.
 * It collects the data stored with each submitted proposal, an immutable copy of most project properties.
 */
export interface IProposalSubmissionData {
  projectId?: number
  submissionDate?: string | Date
  submittedBy?: {
    userId: number
    username: string
    firstName: string
    lastName: string
  }
  bankName: string
  bic: string
  // @todo Kategorien können sich ändern, daher muss der Titel hier gespeichert werden => Kann das Backend die Titel speichern als String?
  categories: ICategory[]// | string[]
  challenges: string
  concretizations: { [id: number]: string }
  contactEmail: string
  contactName: string
  contactPhone: string
  description: string
  descriptionExtension: string
  expectedInvolvedPersons: number
  goals: IGoal[]
  goalExplanation: string
  holderAddressInfo: string
  holderCity: string
  holderName: string
  holderStreet: string
  holderZipCode: string
  delimitation: string
  geoJson: object | [] // @todo replace with GeoJson.Feature | FeatureCollection when the corresponding "map" branch is merged
  iban: string
  implementationBegin: string
  implementationTime: number
  impact: string[]
  name: string
  outcome: string[]
  partners: IProjectPartner[]
  targetGroups: string[]
  results: string[]
  resourceRequirements: IResourceRequirement[]
  sdgExplanation: string
  sdgs: ISDG[] // | string[]
  shortDescription: string
  tasks: IProjectTask[]
  teamContactName: string
  teamContactEmail: string
  utilization: string
  vision: string
  workPackages: IWorkPackage[]
}

/**
 * An IApplicationSubmissionData represents an application to a fund.
 */
export interface IApplicationSubmissionData extends IProposalSubmissionData {
  requestedFunding: number
  thirdPartyFundsExplanation: string
}

/**
 * ISystinfo is used to transfer information about the configuration of the running system from the backend to the client.
 */
export interface ISysinfo {
  settings: {
    frontend_uri: string
    mailer_sender: string
    jwt_token_ttl: number
    user_validation_required: boolean
    project_inactive_threshold: number
    project_inactive_notification_threshold: number
    access_block: {
      [key: string]: {
        interval: string
        limit: number
      }
    }
  }
}

/**
 * An ITeamUpload represents a single upload into the file-storage of a member of a project team.
 * It consists of the uploaded file as well as extra information to handle this file.
 */
export interface ITeamUpload extends INumericIdentifierModel {
  file?: File | IMediaObject
  category?: string
  content?: string
  project?: IProject | string
}



/**
 * An ITeamEmail represents an email, consisting of emaiSubject and emailMessage and a list of emailReceivers.
 */
export interface ITeamEmail {
  emailMessage: string
  emailSubject: string
  emailReceivers?: number[]
}

/**
 * An ITransitionList represents a transition in a workflow and the blockers, that prevent the transition to be performed.
 */
export type ITransitionList = {
  [transitionName in ChallengeTransitionState as string]: {
    blockers: {
      [code: string]: string
    }
  }
}




/**
 * An IUser represents a single user: username, name, email, several states and his memberships to projects.
 */
export interface IUser extends INumericIdentifierModel {
  aboutMe?: string
  active?: boolean // @todo: is currently not really used, but must be true to let the user do things
  createdAt?: Date | string
  /**
   * projects that have been created by the user, even if he no longer is a member of the project team
   * includes created ideas
   */
  createdProjects?: IProject[]
  deletedAt?: Date | string
  email?: string
  firstName?: string
  lastName?: string
  objectRoles?: IUserObjectRoles[]
  password?: string
  picture?: IMediaObject
  /**
   * current "likes" of the user
   */
  followerships?: IProjectFollowership[]
  /**
   * current project memberships of the user
   */
  projectMemberships?: IProjectMembership[]
  roles?: UserRole[]
  self?: boolean
  username?: string
  validated?: boolean
}


/**
 * An IUserObjectRole represents the connection of an user to an object (e.g. a project or an media object)
 * by a special role. It is necessary to organise the role-specific access rights of an user to ab object.
 */
export interface IUserObjectRoles extends IModel {
  objectId?: number
  objectType?: string
  role?: ObjectRoles
  user?: IUser
}

/**
 * IUserStatistics is used to transfer statistic information about the number of users with different states
 * in the running system from the backend to the client.
 */
export interface IUserStatistics extends IStatistics {
  /** number of deleted users */
  deleted: number
  /** number of existing users */
  existing: number
  /** number of users registered within the last 24 hours */
  newlyRegistered: number
  /** number of inactive users */
  notActive: number
  /** number of users that did not validate their mailaddress */
  notValidated: number
}


/**
 * An IWorkPackage represents a work package of a project, than can be a connector to several tasks. Usually a
 * project consists of more than one work package and a work package consists of more than one task.
 */
export interface IWorkPackage {
  description?: string
  id?: string
  mainResponsibility?: string
  name?: string
  // for display (AP1, AP2, ...) and for sorting
  order?: number
  project?: IProject | string
  version?: number
}



/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* using no prefix                                                            */
/* ************************************************************************** */

/**
 * This enum defines available states for the recommendation of a proposal.
 *
 * Notation is specified by the API.
 */
export enum ApprovalRecommendation {
  Open = "open",
  Recommended = "recommended",
  Alternate = "alternate",
  Reject = "reject"
}

/**
 * This enum defines available Types of a Challenge.
 *
 * Notation is specified by the API.
 */
export enum ChallengeType {
  BasicChallenge = "BasicChallenge",
  Fund = "Fund"
}

/**
 * available selection process options to select proposals for granting
 *
 * may be extended by Jury, Voting etc.
 */
export enum ChallengeSelectionProcess {
  /** basic means: selection by process manager */
  Basic = "basic"
}

/**
 * Types of contact topics in a contact form for emailing process managers or the admin of the platform
 * The topic bugReport will lead to email the admins in the Api. Feedback will lead to email the process managers.
 * Currently used in IContactEmail
 *
 * Notation is specified by the API.
 */
export enum ContactTopic {
  BugReport = "bugReport",
  Feedback = "feedback"
}

/**
 * Types of Downloads
 *
 * @todo: is this the right place for this enum?
 */
export enum DownloadType {
  ProposalAttachment = "proposal-attachment",
  ProposalPdf = "proposal-pdf",
  ProposalTeamPdf = "proposal-team-pdf",
  ProjectPdf = "project-pdf",
  TeamUpload = "team-upload",
}

export enum FeedbackInvitationState {
  Active = 'active',
  Expired = 'expired',
  Preparing = 'preparation',
  All = 'all',
}

/**
 * A FeedbackPostType frames possible types of posts, a user may contribute to an discussion by his post
 *
 * Notation is specified by the API.
 */
export enum FeedbackPostType {
  Hint = 'hint',
  Criticism = 'criticism',
  Troll = 'troll',
  Question = 'question',
  Support = 'support'
}

/**
 * FeedbackRoles frames possible roles, that may be allowed to give feedback
 *
 * Notation is specified by the API.
 */
export enum FeedbackRoles {
  Coordinator = 'coordinator',
  Planner = 'planner',
  Observer = 'observer',
  ProcessManager = 'processManager',
  RegisteredUser = 'registeredUser',
}


/**
 * A ProposalState frames the possible states of an proposal for a challenge.
 *
 * Notation is specified by the API.
 */
export enum ProposalState {
  Preparing = "preparing",
  /** a proposal that is finished was submitted before (and may have had other states "after" submitted before being finished)  */
  Finished = "finished", // @todo eventuell State umbenennen in proposal.submissionstate
  Submitted = "submitted",
}

/**
 * States of a proposal transition.
 *
 * Notation is specified by the API.
 */
export enum ProposalTransitionState {
  Submit = "submit",
  Finish = "finish"
}

/**
 * Types of a proposal, based on the Challenge/Fund it is connected to.
 *
 * Notation is specified by the API.
 */
export enum ProposalType {
  BasicProposal = "BasicProposal",
  Fundapplication = "FundApplication"
}

/**
 * ApplicationState describes the additional states of a Fundapplication, additional to ProposalState
 * coming after ProposalState.Submitting
 *
 * Notation is specified by the API.
 */
export enum ApplicationState {
  AwaitingAcception = 'awaiting_acceptation',
  Fulfilling = 'fulfilling',
  Postprocessing = 'postprocessing'
}
/**
 * A FundApplication has the same states like a proposal regarding its progress to a challenge,
 * extended by additional states of a FundApplication.
 *
 * Because it is not possible in typescript to extend an enum the Uniontype is used as a workaround.
 * This solution mirrors the relationship of Proposal and FundApplication in the Backend.
 */
export type FundApplicationState = ProposalState | ApplicationState

/**
 * ChallengeState frames the possible states of a challenge.
 *
 * Notation is specified by the API.
 */
export enum ChallengeState {
  Created = "created",
  Published = "published",
  Open = "open",
  Paused = "paused",
  Deciding = "deciding",
  FulFilling = "fulfilling",
  Clearing = "clearing",
  Finished = "finished",
}

/**
 * ChallengeTransitionState frames the possible transitions of a challenge.
 *
 * Notation is specified by the API.
 */
export enum ChallengeTransitionState {
  Publish = "publish",
  Open = "open",
  Pause = "pause",
  Close = "close",
  Grant = "grant",
  Conclude = "conclude",
  Finish = "finish",
}

/**
 * The ImplementationState frames the state of a project regarding its implementation.
 *
 * Notation is specified by the API.
 */
export enum ImplementationState {
  Planning = "planning",
  Running = "running",
  Finished = "finished",
}

/**
 * A CostSubCategory represents a subtype of costs for (currently) Materialcosts/Sachkosten, e.G. "rent".
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum CostSubCategory {
  //  Administrative = "administrative",
  Honorarium = "honorarium",
  Rent = "rent",
  Subsistence = "subsistence",
  Traveling = "traveling",
  Other = "other"
  //  Fees = "fees",
  //  Custom = "custom", // for custom source types
}

/**
 * A MembershipRole frames the possible roles for a project team member.
 *
 * Notation is specified by the API.
 */
export enum MembershipRole {
  Applicant = "applicant",
  Coordinator = "coordinator",
  Observer = "observer",
  Planner = "planner",
}


export enum ObjectRoles {
  JuryMember = "juryMember",
  ProcessManager = "processManager",
}

/**
 * A ObligationState frames, how a data must be handled: the relevant data is: NotWanted, Required or Optional
 * Currently (07.08.2022) only NotWanted and Required are used in ProposalContent-Definition.
 *
 * Notation is specified by the API.
 */
export enum ObligationState {
  NotWanted = "",
  Optional = "optional",
  Required = "required",
}


/**
 * An PartnershipState frames the options for the status of a partnership
 *
 * Notation is specified by the API.
 */
export enum PartnershipState {
  Accepted = "accepted",
  Open = "open",
  Requested = "requested",
}

/**
 * The ProjectProgress frames possible states for a project progress: how far it has evolved.
 *
 * Notation is specified by the API.
 */
export enum ProjectProgress {
  Idea = "idea",
  CreatingProfile = "creating_profile",
  CreatingPlan = "creating_plan",
  CreatingProposal = "creating_proposal",
  SubmittingProposal = "submitting_proposal",
  ProposalSubmitted = "proposal_submitted",
}

/**
 * The ProjectProgress frames possible states of a project, especially if it is (still) active.
 *
 * Notation is specified by the API.
 */
export enum ProjectState {
  Active = "active",
  Inactive = "inactive",
  Deactivated = "deactivated",
}

/**
 * A ResourceFinancialType frames possible types of costs, to form a consistent resource plan.
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceCostCategory {
  Investment = "investment",
  Material = "material",
  Personnel = "personnel",
  //  Proceeds = "proceeds",
  Other = "other",
  UnSet = ""
}


/**
 * A ResourceSource frames possible sources for ResourceRequirements, depending on the chosen SourceType
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceSource {
  Custom = 'custom',
  Fund = 'fund', // when sourceType === funding
  //  OwnContribution = 'ownContribution', // when sourceType === OwnResources
  OwnFunds = 'ownFunds', // when sourceType === OwnResources
  OwnMaterialContribution = "ownMaterialContribution", // when sourceType === OwnResources
  OwnEffortContribution = 'ownEffortContribution',
  Partner = 'partner', // when sourceType === ThirdParty
}

/**
 * A ResourceUnit frames the options of units, a resource can be measured in.
 *
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * Notation is specified by the API.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceUnit {
  Custom = "custom",
  Flat = "flat",
  Pieces = "pieces",
  Hours = "hours",
  Months = "months",
  Years = "years",
  Kilometers = "kilometers",
  OvernightStays = "overnightStays"
}


/**
 * A ResourceSourceType represents a possible source for a needed resource or the needed amount of money.
 *
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum ResourceSourceType {
  Funding = "funding",
  OwnResources = "ownResources",
  Proceeds = "proceeds",
  ThirdParty = "thirdParty", // for custom source types
}


/**
 * A SelfAssessment frames the options of how far a project or a part of it has evolved. It is used by the users themself,
 * thats why they are called SelfAssessments.
 */
export enum SelfAssessment {
  Starting = 0,
  MakingProgress = 25,
  HalfFinished = 50,
  AlmostFinished = 75,
  Complete = 100,
}

/**
 * TimeDimension frames the options of a monthly or yearly view on time-relevant data, e.g. the ICashFlowEntry.
 *
 * Notation is specified by the API.
 * To add new entries or change or delete entries, it is important to adapt the places in the
 * PROJEKTFABRIK-application, where they are used.
 *
 * @see: /doc/index.md, Abschnitt "ResourceRequirements und ihre Enum(erationen)"
 */
export enum TimeDimension {
  Monthly = "monthly",
  UnSet = "",
  Yearly = "yearly"
}


/**
 * An UserRole frames the options for Roles, that exists in the system.
 *
 * Notation is specified by the API.
 */
export enum UserRole {
  Admin = "ROLE_ADMIN",
  ProcessManager = "ROLE_PROCESS_MANAGER",
  User = "ROLE_USER",

  // @todo should not be an allowed role for any actions, added here for authenticated links
  Guest = "ROLE_GUEST",

  // @todo here for loading users and determining if the entity was loaded when the currentuser
  // was logged in or in the state from registration etc.
  Self = "self",
}

/**
 * Is used to determines in an email, which user will receive the email.
 * Currently, simply to decide, if all users or a selection of users will receive the email.
 */
export enum UserEmailReceiverOption {
  All = "ALL_USERS",
  Selection = "SELECTED_USERS"
  // @todo Question add userRole as Filter?
}
