import { Translate, TranslationQuery } from "next-translate"
import { NextRouter } from "next/router"

import {
  ChallengeState,
  ChallengeTransitionState,
  ChallengeType,
  IChallenge,
  IFund,
  IProject,
  IProposal,
} from "@api/schema"
import { AvailableProposalContentItems, createIProposalContentFromContentItem, DefaultProposalPDFContentSettings } from "@components/common/contentmanagement/ContentManagementHelper"
import { IPageNavigationProps } from "@components/common/PageNavigation"
import { IFilterCriteria } from "@redux/helper/actions"
import { MODULE_CHALLENGE_AVAILABLE, MODULE_FUNDS_AVAILABLE } from "config"

import { BlockerContext, CommonBlockers } from "./blocker"
import { isFund } from "./fundHelper"
import { getResourceRequirementsFundedByFund, sumRequirementsCosts } from "./projectHelper"
import { getStringFromQuery, Routes, routeWithParams } from "./routes"
import { idFromIModelOrIRI, isEmptyNullOrUndefinedObject } from "./util"

/**
 * challengeHelper: Collection of helper-functions for a IChallenge
 *
 */

/**
 * Path to the placeholder-image for challenges
 *
 */
export const challengeLogoPlaceholder = "/assets/img/placeholder/challenge-logo-placeholder.svg"

/**
 * maximum length of a concretization (given by the backend: ChallengeConcretization)
 */
export const maxDefaultConcretizationLength = 10000

/**
 * This enum helps to decide to display submitted or unsubmitted proposals for a challenge.
 *
 * Currently used in the management are, in the challegen workflow step grant as well as proposals.
 * In the grant step, the processmanager grants a proposal and in the proposals workflow step, the
 * processmanger is able to view all submitted and unsubmitted proposals for challenge.
 */
export enum ViewAdminProposalOfProjectTable {
  SubmittedProposals = "submittedProposals",
  UnSubmittedProposals = "UnSubmittedProposals"
}

/**
 * these step are representing the first 3 workflow steps (preparation steps) to create a challenge.
 * Each step is represented by a page.
 */
export enum ChallengeWorkflowPreparationSteps {
  Details = "details",
  ProcedureAndTimeline = "procedureAndTimeline",
  Concretization = "concretization",
}
/**
 * This represents one challenge workflow step after the prepartion steps and submission steps (publish, open) have ended,
 *
 * This step is represented by a page.
 */
export const ChallengeWorkflowStepViewProposals = "viewProposals"

/**
 * this is the first postprocessing step after the submission period has ended.
 *
 * In this step, the process manager preselect the proposals for a approval recommendation.
 * For this step exists an index and an edit page.
 */
export const ChallengeWorkflowSelectProposals = "selectProposals"

/**
 * This steps are reprensenting the whole workflow step from creating a challenge till finishing.
 */
export type ChallengeWorkflowStep = ChallengeWorkflowPreparationSteps | typeof ChallengeWorkflowStepViewProposals | typeof ChallengeWorkflowSelectProposals | ChallengeTransitionState
/**
 * This interface defines, what filter criteria are accepted by the API for FeedbackInvitations
 */
export interface IChallengeFilterCriteria extends IFilterCriteria {
  /** ID of one or more challenge(s) */
  id?: number | number[] | string | string[]
  /** Slug of one or more challenge(s) */
  slug?: string | string[]
  /** name of a challenge */
  name?: string
  /** search pattern for a challenge */
  pattern?: string
  /** state(s) of one/more challenge(s) */
  state?: ChallengeState | ChallengeState[]

  // how do those filter criteria differ?
  "submissionEnd[before]"?: Date
  "submissionEnd[strictly_before]"?: Date
  "submissionEnd[after]"?: Date
  "submissionEnd[strictly_after]"?: Date
}

/**
 * list of Blockers for a challenge to accept submissions
 */
export enum ChallengeSubmissionBlocker {
  SubmissionPeriodNotReached = "common:challenge.blocker.submissionPeriodNotReached",
  SubmissionPeriodPassed = "common:challenge.blocker.submissionPeriodPassed",
  ChallengePaused = "common:challenge.blocker.noSubmissionBecausePaused",
  ChallengeNotOpen = "common:challenge.blocker.noSubmissionBecauseNotOpen",
}


/* ************************************************************************** */
/* Pre-filled data for initial objects based on existing schemas             */
/* using the prefix "Fresh" to have a standard in the code                    */
/* ************************************************************************** */

/**
 * FreshChallenge delivers a pre-filled IChallenge to be used as an standard fresh challenge.
 */
export const FreshChallenge = (type: ChallengeType = ChallengeType.BasicChallenge): IChallenge | IFund => {
  // create a challenge with basic data
  const freshChallenge: IChallenge = {
    "@type": type,
    criteria: [],
    description: "",
    implementationBegin: null,
    imprint: "",
    name: "",
    region: "",
    sponsor: "",
    state: ChallengeState.Created,
    proposalContents: createIProposalContentFromContentItem(DefaultProposalPDFContentSettings)
  }
  // create a fund based on the challenge, extended by fund-specific data
  let freshFund: IFund = null
  if (type === ChallengeType.Fund) {
    freshFund = {
      ...freshChallenge,
      budget: 0,
      costTypesRequired: false,
      customMaterialCostTypes: [],
      fundingDuration: 1,
      fundingRate: 100,
      maxFundedHourlyRate: 10,
      maximumGrant: 0,
      minimumGrant: 0,
      ownContributionsAccepted: false,
    }
  }
  // return the fund, if there is one, otherwise the challenge
  return freshFund ? freshFund : freshChallenge
}

/* ************************************************************************** */
/* helper-functions                                                           */
/* ************************************************************************** */

/**
 * Checking, if the given challenge type is allowed, e.g. a user wants to apply for a challenge (type BasicChallenge). So the function
 * checks, if the corresponding module is active.
 *
 * @param challengeType
 * @returns true, if the corresponding module is active
 */
export const challengeTypeAllowedForSelection = (challengeType: ChallengeType): boolean => {
  switch (challengeType) {
    case ChallengeType.BasicChallenge:
      return MODULE_CHALLENGE_AVAILABLE
    case ChallengeType.Fund:
      return MODULE_FUNDS_AVAILABLE
    default: return false
  }
}

export const isBasicChallenge = (challenge: IChallenge): boolean => challenge && challenge["@type"] === ChallengeType.BasicChallenge

// workmode may be Funds, but default-workmode is Challenges
// considering, which module is active, if a no valid type is giving, but default-workmode is Challenges
export const getChallengeWorkMode = (router: NextRouter): ChallengeType => {
  const workModeType = getStringFromQuery(router, "type")?.toLowerCase()
  if (workModeType === ChallengeType.Fund.toLocaleLowerCase()) {
    return ChallengeType.Fund
  } else if (workModeType === ChallengeType.BasicChallenge.toLocaleLowerCase()) {
    return ChallengeType.BasicChallenge
  } else {
    return MODULE_CHALLENGE_AVAILABLE ? ChallengeType.BasicChallenge : ChallengeType.Fund
  }
}

// #region usecaseKey creator functions

/**
 * Helper to create a standardized unique string
 * to be used as usecaseKey for API-calls
 * for currently not active challenges, selected by a given project.
 *
 * @projectId id of the project
 * @returns a unique string to be used as usecaseKey
 */
export const usecaseKeyForInactiveChallengesByProject = (projectId: number): string =>
  `inactive_challenges_for_project_${projectId.toString()}`

/**
 * Helper to create a standardized unique string
 * to be used as usecaseKey for API-calls
 * for all challenges, selected by one project.
 *
 * @projectId id of the project
 * @returns a unique string to be used as usecaseKey
 */
export const usecaseKeyForChallengesByProject = (projectId: number): string =>
  `challenges_for_project_${projectId.toString()}`

// #endregion

/** returns, if a given challenge has a minimum of 1 concretization */
export const hasConcretizations = (challenge: IChallenge): boolean =>
  challenge?.concretizations?.length > 0
    ? true
    : false

/**
 * Checks if proposal content list of a challenge contains AvailableProposalContentItems.ProposalConcretizations,
 * what means: a proposal to that challenge needs to answer concretization questions
 *
 * @returns true, if the proposalcontents contains AvailableProposalContentItems.ProposalConcretizations
 */
export const challengeRequiresConcretizationsForProposal = (challenge: IChallenge): boolean => {
  return !!challenge.proposalContents.find(proposalContent => proposalContent.contentId === AvailableProposalContentItems.ProposalConcretizations)
}

// #region Availability of challenge => Is a challenge available for submission?
/**
 * Checks if a challenge allows proposals to be submitted
 *
 * @todo rework to Blocker-based function; remove availableForDirectSubmission + readyAndAvailableForIndirectSubmission; is only used once!
 * @todo check: wird diese Funktion (und die Sub-Funktionen) überhaupt noch gebraucht oder ist sie durch die blocker-basierte blockerToAcceptSubmissions bereits ersetzbar?
 *
 * @param challenge The challenge to be checked
 * @returns true, if a challenge is Open
 * and true, if a challenge is open or postprocessing by not direct submission
 */
export const availableForSubmission = (challenge: IChallenge): boolean => {
  return (availableForDirectSubmission(challenge) ||
    readyAndAvailableForIndirectSubmission(challenge))
}

/**
 * Checks if a challenge allows proposals to be directly submitted.
 * Counterpart to readyAndAvailableForIndirectSubmission
 *
 * @todo rework to Blocker-based availableForSubmission + remove this function
 *
 * @param challenge The challenge to be checked
 * @returns true, if a challenge is Published, Open or Paused and submission period is still valid
 */
const availableForDirectSubmission = (challenge: IChallenge): boolean => {
  return (challenge && challenge.directSubmission && isReadyForDirectProposalSubmission(challenge))// those states are acceptable
    && isSubmissionPeriodStillValid(challenge) // the deadline is not yet reached
}

/**
 * Checks if a challenge allows proposals to be submitted outside the platform (indirect submission)
 * Counterpart to availableForDirectSubmission
 *
 * @todo rework to Blocker-based availableForSubmission + remove this function
 *
 * @param challenge The challenge to be checked
 * @returns true, if a challenge is Open or Deciding
 */
const readyAndAvailableForIndirectSubmission = (challenge: IChallenge): boolean => {
  return challenge && !challenge.directSubmission && [ChallengeState.Open, ChallengeState.Deciding].includes(challenge.state)
}

/**
 * A challenge with direct submission is available for submission, if the challenge state is Open.
 *
 * @param challenge
 * @returns true, if the state is Open.
 */
export const isReadyForDirectProposalSubmission = (challenge: IChallenge): boolean => challenge && challenge.directSubmission && [ChallengeState.Open].includes(challenge.state)


/**
 * Returns an BlockerContext with ordered list of blockers, why the given challenge is not accepting proposals.
 * Use entry number 0 to get the most urgent/most global blocker; or show all entries to give the user
 * a complete overview. If the returned BlockerContext has no entries, the challenge accepts submissions:
 * no blockers found!
 *
 * This function should be the single point of truth for "challenge accepts submissions" and all other functions
 * should use this function!
 *
 * NOTE: the resulting translation keys may need params, that should be given as parameters
 * when translating the keys. Known params: (25.07.2023)
 * submissionBegin
 * submissionEnd
 *
 * @param challenge a challenge to be checked
 * @returns a list of translation keys of the blockers, why a given challenge does not accept proposal submissions
 */
export const blockerToAcceptSubmissions = (challenge: IChallenge): BlockerContext => {
  const blockers: BlockerContext = {
    blocker: []
  }

  // technical checks
  if (isEmptyNullOrUndefinedObject(challenge)) {
    return { blocker: [CommonBlockers.ObjectNotFound] }
  }

  // challenge checks
  if (!MODULE_CHALLENGE_AVAILABLE && !isFund(challenge)) {
    blockers.blocker.push(CommonBlockers.ModuleNotAvailable)
  }
  if (!MODULE_FUNDS_AVAILABLE && isFund(challenge)) {
    blockers.blocker.push(CommonBlockers.ModuleNotAvailable)
  }
  if (hasNotReachedSubmissionBeginYet(challenge)) {
    blockers.blocker.push(ChallengeSubmissionBlocker.SubmissionPeriodNotReached)
  }
  // directSubmission means: submissions are available on the platform, that means: deadline is hard
  // no directSubmission means: user submisses the proposal indirectly (per mail) and just "informs" the platform about it: so the deadline is not hard
  if (hasPassedSubmissionEnd(challenge) && challenge.directSubmission) {
    blockers.blocker.push(ChallengeSubmissionBlocker.SubmissionPeriodPassed)
  }
  if (challenge.state !== ChallengeState.Open) {
    if (challenge.state === ChallengeState.Paused) {
      blockers.blocker.push(ChallengeSubmissionBlocker.ChallengePaused)
    }
    else {
      blockers.blocker.push(ChallengeSubmissionBlocker.ChallengeNotOpen)
    }
  }
  // NOTE for coders: expand the reason list in an ordered form! More global or urgent blockers should be
  // checked earlier than less urgent/global blockers!

  return blockers
}

/**
 * arranges all (known) params for submission blockers to be shown correctly to the user
 */
export const paramsForSubmissionBlocker = (challenge: IChallenge, project: IProject, pageUrlForRedirectBack: string, t: Translate): {
  currentGrant: string
  maximumGrant: string
  minimumGrant: string
  concretizationLink: string
  financePlanLink: string
  projectPlanLink: string
  projectProfileLink: string
  submissionBegin: string
  submissionEnd: string
} => {
  return {
    // grant
    currentGrant: t("default.currency", { value: requestedFunding(challenge, project) || 0 }),
    maximumGrant: t("default.currency", { value: (challenge as IFund).maximumGrant || 0 }),
    minimumGrant: t("default.currency", { value: (challenge as IFund).minimumGrant || 0 }),
    // links
    concretizationLink: routeWithParams(Routes.ProjectConcretization, { slug: project.slug || project.id }, pageUrlForRedirectBack),
    financePlanLink: routeWithParams(Routes.ProjectPlanFinances, { slug: project.slug || project.id }, pageUrlForRedirectBack),
    projectPlanLink: routeWithParams(Routes.ProjectPlan, { slug: project.slug || project.id }, pageUrlForRedirectBack),
    projectProfileLink: routeWithParams(Routes.ProjectProfile, { slug: project.slug || project.id }, pageUrlForRedirectBack),
    // timeline
    ...paramsForSubmissionTimelineBlocker(challenge, t)
  }
}

/**
 * arranges submission timeline blockers for a challenge
 */
export const paramsForSubmissionTimelineBlocker = (challenge: IChallenge, t: Translate): { submissionBegin: string; submissionEnd: string } => {
  return {
    // timeline
    submissionBegin: t("default.date", { value: challenge?.submissionBegin }),
    submissionEnd: t("default.date", { value: challenge?.submissionEnd }),
  }
}

/**
 * challenge states after the submission period ended. If the challenge is in these states, a proposal can't be submitted anymore.
 *
 */
export const challengeStatesAfterAllowingToSubmitProposals = (challenge: IChallenge): boolean => {
  return !isEmptyNullOrUndefinedObject(challenge)
    && challenge.directSubmission
    ? [ChallengeState.Deciding, ChallengeState.FulFilling, ChallengeState.Clearing, ChallengeState.Finished].includes(challenge.state)
    : [ChallengeState.FulFilling, ChallengeState.Clearing, ChallengeState.Finished].includes(challenge.state)
}

/**
 * Returns, if NOW is between start and end date of the submission phase of the given challenge.
 *
 * @param challenge the challenge to be checked
 * @returns true, if NOW is between submissionBegin and submissionEnd
 */
export const isSubmissionPeriodStillValid = (challenge: IChallenge): boolean =>
  challenge && challenge.submissionBegin && challenge.submissionEnd
  && (new Date() > new Date(challenge.submissionBegin)) && (new Date() < new Date(challenge.submissionEnd))


/**
 * A challenge is available for proposals, if the state is published or open and if the submission end is still in the future
 *
 * @todo transform to Blocker-based function
 */
export const availableForSelection = (challenge: IChallenge): boolean => {
  return !isEmptyNullOrUndefinedObject(challenge) && [ChallengeState.Open, ChallengeState.Published].includes(challenge.state) && (new Date() < new Date(challenge.submissionEnd))
}

// #endregion Availability of challenge


/**
 *
 * returns the funding, that a given IProject requests of a specific IFund
 * or false, if the given IChallenge is not a fund
 *
 * */
export const requestedFunding = (challenge: IChallenge, project: IProject): number | boolean => isFund(challenge) && sumRequirementsCosts(getResourceRequirementsFundedByFund(project, challenge))


/**
 * Returns that challenge from a given challenge list, that is connected to the active proposal
 * of a given project.
 *
 * @param project the project with its proposals
 * @param challenges a list of challenges to be checked
 * @returns the challenge to the active proposal of the given project
 */
export const getCurrentChallenge = (project: IProject, challenges: IChallenge[]): IChallenge => {
  // get the proposal, its corresponding challenge
  const currentProposal = project.proposals?.find((p) => p.active)
  return challenges?.find(f => f.id === idFromIModelOrIRI(currentProposal?.challenge))
}

/**
 * Creates a hashtag challenge like '#challenge-5' in order to add it on the url-adress.
 *
 * @param challenge
 * @returns a string, e.g. '#challenge-5', if challenge is defined
 */
export const hashTagChallengeId = (challenge: IChallenge): string => (challenge ? `#challenge-${challenge.id.toString()}` : '')

/**
 * Preparing a list of blockers for a given transition. If the transition is not allowed, the returned object
 * contains the reasons why it is forbidden (TransitionBlockers).
 *
 * @todo make compatible to system wide blocker system
 *
 * @param challenge
 * @param transition
 * @returns an object, which contains one more codes (identifier of a TransitionBlocker) and the message itself
 */
export const blockersForTransition = (challenge: IChallenge, transition: ChallengeTransitionState): { code: string; msg: string }[] => {
  return challenge?.transitions && Object.keys(challenge?.transitions[transition]?.blockers || {}).map(code => {
    return { code, msg: challenge.transitions[transition].blockers[code] }
  })
}

/**
 * This function helps to translate a translation for Fund or BasicChallenge
 * and to avoid double translation content in the translation files:
 *
 * e.g.: given basic translation key
 * page.challenge.workflow.procedureAndTimeline.edit.title.Fund
 * does not deliver an entry, so
 * page.challenge.workflow.procedureAndTimeline.edit.title
 * is taken
 *
 * usage:
 * challengeTranslation(`page.challenge.workflow.${workflowStep}.${mode}.heading`, challenge?.["@type"], t)
 * at which t is the useTranslation-function
 *
 * When using this function it is more easy to add or remove translation keys with the extension .Fund
 * or .BasicChallenge -> the function checks if this extension exists and uses the basicTranslationKey if not.
 *
 * @param translationKey the key of a translation string, without .Fund or .BasisChallenge at the end.
 * @param challengeType the type to which the translation should be performed
 * @param t the function to translate the key
 * @param params additional parameters for the translation, e.g. to display certain number of challenges
 * @returns a translation
 */
export const challengeTranslation = (basicTranslationKey: string, additionalChallengeType: ChallengeType, t: Translate, params?: TranslationQuery | null): string => {
  // if there is no translation function
  // return the basicTranslationKey: it is especially necessary for tests
  if (!t) {
    return basicTranslationKey
  }
  // the "full" translationKey extends the translationKey by adding ".Fund" or ".BasicChallenge"
  const fullTranslationKey = basicTranslationKey + "." + additionalChallengeType
  // handle the translation
  const fullTranslation = t(fullTranslationKey, params)
  // if the translation of the full key returns the key itself:
  // no specific translation for .Fund or .BasicChallenge is available
  if (fullTranslation === fullTranslationKey) {
    // so return the translation with the translation of the basicTranslationKey, without the extension of .Fund or .BasicChallenge
    return t(basicTranslationKey, params)
  } else {
    // if there is a specific translation return it
    return fullTranslation
  }
}

/** ****************************************************************************************
 * The following section is about helper methods for handling the challenge workflow PageNavigation
 *
 * Follow links should be provided on certain pages, if the user has the rights and the conditions for the links are fullfilled:
 * 'which page' => left link, center link, right link:
 *
 * AdminChallengeDetails => empty, challenge-dashboard, AdminChallengeProcedureAndTimeLine|AdminChallengeViewProposal|AdminChallengeSelectProposals
 * AdminChallengeProcedureAndTimeLine => AdminChallengeDetails, challenge-dashboard, AdminChallengeConcretizations
 * AdminChallengeConcretizations => AdminChallengeProcedureAndTimeLine, challenge-dashboard, AdminChallengePublish|AdminChallengeOpen
 * Transition->Publish => AdminChallengeConcretizations, challenge-dashboard, empty
 * Transition->Open => AdminChallengeConcretizations, challenge-dashboard, empty
 * AdminChallengeProposal => AdminChallengeDetails, challenge-dashboard, AdminChallengeSelectProposals
 * AdminChallengeSelectProposals => AdminChallengeDetails, challenge-dashboard, AdminChallengeGrant
 * AdminChallengeGrant => AdminChallengeSelectProposals,challenge-dashboard, AdminChallengeConclude|AdminChallengeFinish|empty
 * AdminChallengeProjectsEdit => AdminChallengeSelectProposals, challenge-dashboard,empty
 * Transition->Conclude => AdminChallengeGrant, challenge-dashboard, empty
 * Transition->Finish => AdminChallengeGrant, challenge-dashboard, empty
 *
 * @todo: Transition->Pause and Transition->CLOSE are not mentioned here -> but is it useful to document those details here? It would be better it can be seen quickly from a declaration
 * @todo refactor to Blocker-based functions
 *******************************************************************************************/

/** *************************************** */
/* helper functions for the workflow        */
/* **************************************** */

/**
 * the challenge basis data may only be edited when
 * the challenge is Created, Published or Paused
 *
 * It means also, that the pages/cards for challenge details, timeline&procedere and concretizations are visible.
 *
 */
export const preparationWorkflowStepsAreVisible = (challenge: IChallenge): boolean => {
  return !!challenge && !!challenge.state &&
    [ChallengeState.Created, ChallengeState.Published, ChallengeState.Paused].includes(challenge.state)
}

/**
 * all challenge detail fields are already required when the challenge is created and can not be reset,
 * so we only have to check the implementationBegin here
 */
export const implementationBeginAfterToday = (challenge: IChallenge): boolean =>
  !!challenge && !!challenge.implementationBegin
  && (new Date(challenge.implementationBegin) > new Date())

/**
 * The submission begin is still in the future.
 */
export const hasNotReachedSubmissionBeginYet = (challenge: IChallenge): boolean =>
  (!isEmptyNullOrUndefinedObject(challenge) && !!challenge.submissionBegin)
  && (new Date() < new Date(challenge.submissionBegin))

/**
 * The submission end has passed
 */
const hasPassedSubmissionEnd = (challenge: IChallenge): boolean => (new Date() > new Date(challenge.submissionEnd))

/**
 * a submission timeline is valid, when both dates are set
 * the submissionBegin is before the submissionEnd
 */
export const hasValidSubmissionTimeline = (challenge: IChallenge): boolean =>
  (!!challenge && !!challenge.submissionBegin && !!challenge.submissionEnd)
  && new Date(challenge.submissionBegin) < new Date(challenge.submissionEnd)


/**
 * The function checks if the challenge state is fulfilling, clearing or finished.
 *
 * It means, the submission period has ended and the proposals have been granted.
 */
export const challengeStateIsLaterThanDeciding = (challenge: IChallenge): boolean =>
  !!challenge && challengeStatesAfterAllowingToSubmitProposals(challenge) && challenge.state !== ChallengeState.Deciding

/**
 * The following data have to be filled out, in order to mark the page challenge details as complete:
 * criterias, description, implementationBegin, imprint, name and region
 *
 * Hint: That is not a validation function.
 */
export const challengeDetailsDataIsComplete = (challenge: IChallenge): boolean =>
  !!challenge
  && challenge.criteria?.length > 0
  && challenge.description?.length > 0
  && challenge.implementationBegin
  && challenge.imprint?.length > 0
  && challenge.name?.length > 0
  && challenge.region?.length > 0
/**
 * The following data have to be filled out, in order to mark the page procedure and timeline as complete:
 * submissionBegin, submissionEnd, selectionProcess, directSubmission, and proposalContents
 *
 * Hint: That is not a validation function.
 */
export const challengeProcedureAndTimelineDataIsComplete = (challenge: IChallenge): boolean =>
  !!challenge
  && challenge.submissionBegin
  && challenge.submissionEnd
  && challenge.selectionProcess?.length > 0
  && !!challenge.directSubmission
  && challenge.proposalContents?.length > 0

// #region workflow check functions

/** ****************************************************** */
/* page functions for the workflow and the page navigation */
/* Should be a page visible or/and editable                */
/**
 * @todo refactor to Blocker-based functions
 */
/* ******************************************************* */


/**
 * Should the edit page of challenge details be accessible?
 *
 * The page is accessible, if the challenge is in the state created, published or paused.
 */
export const pageChallengeDetailsEditIsAccessible = (challenge: IChallenge): boolean =>
  preparationWorkflowStepsAreVisible(challenge)

/**
 * Should be the index pages of concretization page or the procedure and timeline page in the management area accessible?
 *
 * These pages should accessible, when the base data is complete (base data is filled out in the first card) and the state
 * of the challenge is created or published.
 * Additionally should these page be accessible, when the state of the challenge is paused.
 */
export const pageChallengeConcretizationAndProcedureTimelineIsAccessible = (challenge: IChallenge): boolean =>
  (!isEmptyNullOrUndefinedObject(challenge) && challengeDetailsDataIsComplete(challenge) && (challenge.state === ChallengeState.Created || challenge.state === ChallengeState.Published))
  || challenge?.state === ChallengeState.Paused
/**
 * Should be the concretizations or procedure and timeline in the management area editable?
 *
 * Yes, if no projects have selected the challenge and
 * if either the challenge details are complete and the challenge state is created or published
 * or simply the challenge state is paused
 */
export const pageChallengeConcretizationAndProcedureTimelineIsEditable = (challenge: IChallenge, proposals: IProposal[]): boolean => {
  const proposalsByChallenge = proposals?.filter(p => idFromIModelOrIRI(p.challenge) === challenge?.id)
  return !isEmptyNullOrUndefinedObject(challenge) && pageChallengeConcretizationAndProcedureTimelineIsAccessible(challenge) && proposalsByChallenge?.length === 0
}

/**
 * Should be the challenge page PUBLISH in the management area visible?
 *
 * The challenge page should only be visible, if the challenge has the state created.
 */
export const pageChallengePublishIsVisible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && challenge.state === ChallengeState.Created

/**
 * Should be the challenge page PUBLISH in the management area accessible?
 *
 * The page should be accesible, if the entered data for challenge details (first card) and
 * procedure and timeline is complete and valid.
 */
export const pageChallengePublishIsAccessible = (challenge: IChallenge): boolean => {
  return !isEmptyNullOrUndefinedObject(challenge) && challengeDetailsDataIsComplete(challenge)
    && implementationBeginAfterToday(challenge)
    && hasValidSubmissionTimeline(challenge)
    && hasNotReachedSubmissionBeginYet(challenge)
}

/**
 * Should be the challenge page OPEN in the management area visible?
 *
 * The challenge page should only be visible, if the challenge has the state paused or published.
 */
export const pageChallengeOpenIsVisible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && (challenge.state === ChallengeState.Paused || challenge.state === ChallengeState.Published)
/**
 * Should be the challenge page OPEN in the management area accessible?
 *
 */
export const pageChallengeOpenIsAccessible = (challenge: IChallenge): boolean => {
  return !isEmptyNullOrUndefinedObject(challenge) &&
    (
      (challenge.state === ChallengeState.Paused
        && challengeDetailsDataIsComplete(challenge)
        && challengeProcedureAndTimelineDataIsComplete(challenge))
      || (challenge.state === ChallengeState.Published
        && challengeDetailsDataIsComplete(challenge)
        && implementationBeginAfterToday(challenge)
        && challengeProcedureAndTimelineDataIsComplete(challenge)
        && hasValidSubmissionTimeline(challenge)
        && hasNotReachedSubmissionBeginYet(challenge))
    )

}
/**
 * Should be the challenge page view proposals in the management area visible and accessible?
 */
export const pageChallengeViewProposalsIsAccessible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && !preparationWorkflowStepsAreVisible(challenge)

/**
 * The page select proposals as well as the page grant is only visible
 * after the challenge have been opened and is not paused.
 */
export const pageChallengeSelectProposalsAndGrantAreVisible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && !preparationWorkflowStepsAreVisible(challenge)
/**
 * Should be the challenge page select proposals in the management area accessible?
 *
 * The page is accessible after the challenge have been opened and is not paused.
 */
export const pageChallengeSelectProposalsIsAccessible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && !preparationWorkflowStepsAreVisible(challenge)

/**
 * Should be the challenge page grant (index page) in the management area accessible?
 *
 * The page is accessible after submission period has ended.
 */
export const pageChallengeGrantIsAccessible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && challengeStatesAfterAllowingToSubmitProposals(challenge)

/**
 * Should be the challenge page grant (editing page) in the management area accessible?
 *
 * The page is accessible is only acessible, if the challenge has the state deciding.
 */
export const pageChallengeGrantEditIsAccessible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && challenge.state === ChallengeState.Deciding

/**
 * The page conclude is only visible, if the challenge has the state Open, deciding or fulfilling.
 */
export const pageChallengeConcludeIsVisible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) &&
  (challenge.state === ChallengeState.Open
    || challenge.state === ChallengeState.Deciding
    || challenge.state === ChallengeState.FulFilling)
/**
 * Should be the challenge page conclude in the management area accessible?
 */
export const pageChallengeConcludeIsAccessible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && challenge.state === ChallengeState.FulFilling

/**
 * Should be the challenge page finish in the management area accessible?
 */
export const pageChallengeFinishIsAccessible = (challenge: IChallenge): boolean =>
  !isEmptyNullOrUndefinedObject(challenge) && challenge.state === ChallengeState.Clearing
// #endregion

// #region navigation helper

/**
 * This function returns the navigation links (IPageNavigationProps) by the ChallengeTransitionState.
 *
 * This function is used in the transition pages: pages/management/[id]/...: close, conclude, finish, open, pause, publish and grant.
 *
 * Not used in pages/management/[id]/view-proposals.tsx, because it is not a transition page, but it is part of the transition UI workflow.
 *
 * @param challengeTransition
 * @param challenge
 * @param t function to translate used translation keys
 * @returns IPageNavigationProps
 */
export const getPageNavigationPropsByChallengeTransition = (challengeTransition: ChallengeTransitionState, challenge: IChallenge, t: Translate): IPageNavigationProps => {
  // shortcut to the challenge type to be used in the translations
  const type = challenge["@type"]

  switch (challengeTransition) {
    case ChallengeTransitionState.Open:
    case ChallengeTransitionState.Publish:
      return {
        leftNavigation: {
          href: pageChallengeConcretizationAndProcedureTimelineIsAccessible(challenge)
            ? routeWithParams(Routes.AdminChallengeConcretization, { id: challenge.id })
            : null,
          title: challengeTranslation("management:goto.challenge.adminChallengeConcretization", type, t)
        },
        centerNavigation: {
          href: routeWithParams(Routes.AdminChallengeOverview, { type }) + hashTagChallengeId(challenge),
          title: challengeTranslation('management:goto.challenge.management', type, t)
        },
      }
    case ChallengeTransitionState.Finish:
    case ChallengeTransitionState.Conclude:
      return {
        leftNavigation: pageChallengeSelectProposalsAndGrantAreVisible(challenge)
          ? {
            href: pageChallengeGrantEditIsAccessible(challenge)
              ? routeWithParams(Routes.AdminChallengeGrantEdit, { id: challenge.id })
              : pageChallengeGrantIsAccessible(challenge)
                ? routeWithParams(Routes.AdminChallengeGrant, { id: challenge.id })
                : null,
            title: pageChallengeGrantEditIsAccessible(challenge)
              ? challengeTranslation("management:goto.challenge.adminChallengeGrantEdit", type, t)
              : challengeTranslation("management:goto.challenge.adminChallengeGrant", type, t)
          }
          : null,
        centerNavigation: {
          href: routeWithParams(Routes.AdminChallengeOverview, { type }) + hashTagChallengeId(challenge),
          title: challengeTranslation("management:goto.challenge.management", type, t)
        },
      }
    case ChallengeTransitionState.Grant:
      return {
        leftNavigation: {
          href: pageChallengeSelectProposalsIsAccessible(challenge)
            ? routeWithParams(Routes.AdminChallengeSelectProposals, { id: challenge.id })
            : null,
          title: challengeTranslation("management:goto.challenge.adminChallengeSelectProposals", type, t)
        },
        centerNavigation: {
          href: routeWithParams(Routes.AdminChallengeOverview, { type }) + hashTagChallengeId(challenge),
          title: challengeTranslation("management:goto.challenge.management", type, t)
        },
        rightNavigation: pageChallengeConcludeIsVisible(challenge)
          ? {
            href: pageChallengeConcludeIsAccessible(challenge) ? routeWithParams(Routes.ChallengeTransitionPage, { id: challenge.id, transition: ChallengeTransitionState.Conclude }) : null,
            title: challengeTranslation("management:goto.challenge.adminChallengeConclude", type, t)
          }
          : pageChallengeFinishIsAccessible(challenge)
            ? {
              href: routeWithParams(Routes.ChallengeTransitionPage, { id: challenge.id, transition: ChallengeTransitionState.Finish }),
              title: challengeTranslation("management:goto.challenge.adminChallengeFinish", type, t)
            }
            : null
      }
    case ChallengeTransitionState.Pause:
    case ChallengeTransitionState.Close:
    default:
      return {
        centerNavigation: {
          href: routeWithParams(Routes.AdminChallengeOverview, { type: challenge?.["@type"] }) + hashTagChallengeId(challenge),
          title: challengeTranslation('management:goto.challenge.management', type, t)
        },
      }
  }
}

// #endregion